import LoginPage from "page/Login/LoginPage";
import ResetPassword from "page/Login/ResetPassword";
import { ReactElement, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "style/login.css";
import { LoginDiv, WrapperLogin } from "style/theme";
import SignUp from "./SignUp";

export function MainPage(): ReactElement {
  const location = useLocation();
  const { hash } = location;
  const history = useHistory();

  const onChange = useCallback(
    (type: string) => {
      history.push({
        ...location,
        hash: "#" + type,
      });
    },
    [history, location]
  );

  const GetPage = () => {
    if (hash === "#password") {
      return <ResetPassword onChange={onChange}></ResetPassword>;
    } else if (hash === "#signup") {
      return <SignUp onChange={onChange}></SignUp>;
    } else {
      //signin
      return <LoginPage onChange={onChange}></LoginPage>;
    }
  };

  return (
    <WrapperLogin>
      <LoginDiv>
        <img src="/images/main_logo.png" width="200px" alt="InBody"></img>
        <hr />
        {GetPage()}
      </LoginDiv>
    </WrapperLogin>
  );
}

export default MainPage;
