import { Grid } from "@mui/material";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { FormButton } from "style/theme";
import DetailForm from "./DetailForm";

function MultiDetailMain() {
  const queryParams = new URLSearchParams(window.location.search);
  const ids = queryParams.getAll("id");
  const printRef = useRef<HTMLDivElement>(null);

  return (
    <Grid container>
      <Grid item xs={6}>
        <ReactToPrint trigger={() => <FormButton>전체 인쇄</FormButton>} content={() => printRef.current} />
      </Grid>
      <Grid item xs={12} ref={printRef}>
        {ids.map((i) => (
          <DetailForm caseId={i}></DetailForm>
        ))}
      </Grid>
    </Grid>
  );
}

export default MultiDetailMain;
