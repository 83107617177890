import React, { ReactNode } from "react";
import { ContentWrapper, ContentShift, ContentBody } from "../../style/theme";

interface ContentProps {
  children: ReactNode;
  open: boolean;
}

function Content({ children, open }: ContentProps) {
  if (open) {
    return (
      <>
        <ContentWrapper>
          <ContentBody>{children}</ContentBody>
        </ContentWrapper>
      </>
    );
  } else {
  }
  return (
    <>
      <ContentShift>
        <ContentBody>{children}</ContentBody>
      </ContentShift>
    </>
  );
}

export default Content;
