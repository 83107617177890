import AccountCircle from "@mui/icons-material/AccountCircle";
import LanguageIcon from "@mui/icons-material/Language";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, IconButton, Menu, MenuItem, NativeSelect, Toolbar, Typography } from "@mui/material";
import PersistentDrawerLeft from "components/Common/PersistentDrawerLeft";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import UserApi from "system/api/UserApi";
import { useUserDispatch, useUserState } from "system/context/UserContext";
import { CommonRoutes, UserRoutes } from "system/types/routeList";
import { MenuButton, Title } from "../../style/theme";

interface HeaderProps {
  open: boolean;
  setOpen: (status: boolean) => void;
}

function Header({ open, setOpen }: HeaderProps) {
  const { i18n } = useTranslation();
  const history = useHistory();
  const user = useUserState();
  const userDispatch = useUserDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const goProfile = () => {
    history.push({
      pathname: UserRoutes.profile,
      state: {
        userId: user.id,
      },
    });
    setAnchorEl(null);
  };

  const onLogout = () => {
    UserApi.Logout().then(() => {
      userDispatch({ type: "LOGOUT" });
      history.replace(CommonRoutes.login);
    });
    setAnchorEl(null);
  };

  const changeLang = (e: any) => {
    if (e.target.value === "en") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("ko");
    }
  };

  return (
    <>
      <AppBar color="inherit" position="fixed" style={{ zIndex: 2000 }}>
        <Toolbar style={{ minWidth: "500px" }}>
          <MenuButton>
            <IconButton edge="start" onClick={handleDrawerOpen} color="inherit" aria-label="menu" size="large">
              <MenuIcon />
            </IconButton>
          </MenuButton>
          <NavLink to="/">
            <img src="/images/main_logo.png" width="100px" style={{ display: "block" }} alt="main_logo"></img>
          </NavLink>
          <Title>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              CS Web
            </Typography>
          </Title>
          <LanguageIcon />
          <NativeSelect style={{ paddingLeft: "5px" }} defaultValue={"ko"} onChange={changeLang}>
            <option value={"ko"}>Korean</option>
            <option value={"en"}>English</option>
          </NativeSelect>
          <div>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{ zIndex: 3000 }}
            >
              <MenuItem onClick={goProfile}>My Profile</MenuItem>
              {user.authority === "Admin" && (
                <MenuItem onClick={() => history.push(UserRoutes.account)}>Account</MenuItem>
              )}
              <MenuItem onClick={onLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <div>
        <PersistentDrawerLeft open={open}></PersistentDrawerLeft>
      </div>
    </>
  );
}

export default Header;
