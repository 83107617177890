import { styled, Typography } from "@mui/material";

const HeaderTitleBar = styled("div")(({ theme }) => ({
  marginBottom: "4px",
}));

interface SubTitleDivProps {
  title: string;
}

function SubTitleDiv({ title }: SubTitleDivProps) {
  return (
    <HeaderTitleBar>
      <Typography variant="body2" fontWeight="bold">
        {title}
      </Typography>
    </HeaderTitleBar>
  );
}

export default SubTitleDiv;
