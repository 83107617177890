import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#e3165b",
    },
  },
});

const PasswordInput: React.FC<TextFieldProps> = (props) => {
  const [passwordIsMasked, setPasswordIsMasked] = useState(true);

  const togglePasswordMask = () => {
    setPasswordIsMasked((prevPasswordIsMasked) => !prevPasswordIsMasked);
  };

  return (
    <CssTextField
      color="secondary"
      {...props}
      type={passwordIsMasked ? "password" : "text"}
      InputProps={{
        style: { height: "52px" },
        endAdornment: (
          <InputAdornment position="end">
            {passwordIsMasked ? (
              <VisibilityOff onClick={togglePasswordMask} />
            ) : (
              <Visibility onClick={togglePasswordMask} />
            )}
          </InputAdornment>
        ),
        autoComplete: "off",
      }}
    />
  );
};

export default PasswordInput;
