import { useLocation } from "react-router-dom";

function useLocationSearch() {
  const location = useLocation<any>();
  if (location.search) {
    return new URLSearchParams(location.search).toString();
  }
  return "";
}

export default useLocationSearch;
