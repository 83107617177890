import moment from "moment";
import {
  ChangeCategory,
  Contact, CSInfo,
  CSR, CSRSearch, CustInfo,
  EquipSetting,
  Message, ProdDate, ProdInfo, ResultSheet, SalesDate, SearchCalendar, SearchCRM, SearchDelivery, SearchResult, ServiceInfo, SOList, User, VisitDetail, VisitMemo
} from "system/types/types";
export function GetInitSearchResult<T>(ary: T[]): SearchResult<T> {
  return {
    list: ary,
    page: 1,
    count: 0,
    totalPage: 1,
  };
}
export const INIT_CHANGECATEGORY: ChangeCategory = {
  origin: "",
  new: "",
  isShow: false,
  order: 0,
}
export const INIT_USER: User = {
  id: "",
  name: "",
  email: "",
  password: "",
  authority: "",
  branch: "",
};
// product search
export const INIT_RESULTSHEET: ResultSheet = {
  isUseInBodyResultSheet: false,
  isUseInBodyChildResultSheet: false,
  isUseBodyTypeResultSheet: false,
  isUseMealResultSheet: false,
  isUseWaterResultSheet: false,
  isUseThermalResultSheet: false,
  isUseOptionResultSheet: false,
  isUseResearchResultSheet: false,
  isUseCompareResultSheet: false,
  isUseVisceralFatResultSheet: false,
}
export const INIT_EQUIPSETTING: EquipSetting = {
  equipSerial: "",
  equip: "",
  countryCodeISO: "None",
  language: "None",
  useMode: "None",
  userManageMode: "None",
  weightInputMode: "None",
  unit: "None",
  datetime: "",
  autoPrint: "",
  resultSheetDetail: INIT_RESULTSHEET,
  isResultSheetPaperA4: true,
  isUseLogo: false,
  isNetworkConnection: false,
  isBluetoothConnection:false,
  isConnectPrint: false,
  connectSerialPort: "",
  apiUrl: "",
  isUseCloud: false,
  isLoginLB: false,
  isUseKillSwitchMode: false,
  isOnKillSwitch: false,
  LBLoginID: ""
}
// crm
export const INIT_CRMSEARCH: SearchCRM = {
  classify: "customerName",
  text: "",
  startDate: "",
  endDate: "",
  dateType: "receivedDate",
  handlingMethod: "Blank",
  resultForVisit: "Blank",
  paymentType: "Blank",
  branch: "",
  parentCategory: "",
  category: [],
  ngCategory: "",
  ngCategoryDetail: "",
  resultCategory: "",
  resultCategoryDetail: "",
  type: "전체보기",
  erp: 2,
  so: 2,
  page: 1
}
export const INIT_PRODDATE: ProdDate = {
  fromProdDate: "",
  toProdDate: ""
}
export const INIT_SALESDATE: SalesDate = {
  fromSalesDate: "",
  toSalesDate: "",
}
export const INIT_CSRSEARCH: CSRSearch = {
  classify: "tel",
  text: "",
  parentCategory: "전체",
  category: "전체",
}
export const INIT_CONTACT: Contact = {
  contactId: 0, 
  custId: 0,
  locator: "", 
  isPrimary: "No", 
  type: "Phone",
  description: "",
}
export const INIT_CUSTINFO: CustInfo = {
  custId: 0,
  custName: "", 
  custAccount: "",
  state: "",
  city: "",
  street: "",
  branch: "", 
  contactInfo: [{...INIT_CONTACT}],
}
export const INIT_PRODINFO: ProdInfo = {
  prodId: 0,
  categoryName: "",
  parentCategoryName: "", 
  prodDate: "", 
  productionPerson: "",  
  reqSetupDate: "", 
  responsible: "",
  salesDate: "", 
  salesOrigin: false, 
  salesPartyName: "", 
  serialNum: "",
  setupPartyName: "", 
  setupResponsible: "", 
  warrantyTerm: "",
  warranty: "", 
  memo: "",
  di: "",
  accident: false
}
export const INIT_CSINFO: CSInfo = {
  caseId: "", 
  custId: 0,
  prodId: 0,
  handlingMethod: "Call", 
  receivedDate: moment().format("YYYY-MM-DD"), 
  receivedPerson: "", 
  receivedBranch: "",
  ngCategoryId: "",
  ngCategoryDetailId: "", 
  completedDate: "", 
  completedPerson: "", 
  resultCategoryId: "", 
  resultCategoryDetailId: "",
  callResult: "NotCall", 
  serviceBillType: "Charge", 
  csReceivedTime: moment().format("HH:mm"), 
  estimatedDateofVist: "", 
  estimatedTime: "", 
  visitor: "", 
  issueDate: "", 
  createdAt: "", 
  updatedAt: "",
  contactMemo: "", 
  caseNote: "", 
  countCheck: "",
  main: "",
  display: "", 
  print: "", 
  digital: "", 
  analog: "", 
  resultForVisit: "Blank", 
  paymentType: "Blank", 
  focReason: "Blank", 
  serviceNote: "", 
  erp: false,
}
export const INIT_SERVICEINFO: ServiceInfo = {
  caseId: "",
  companyName: "",
  companyAccount: "",
  companyNum: "",
  companyPerson: "",
  email: "",
  state: "",
  city: "",
  street: "",
  activity: "",
  project: "",
  costCenter: "",
  depositor: "",
  depositDate: "",
  payment: "해당없음",
  receiptSend: "필요없음",
  note: "",
  personnelNumber: "",
  creditCardNo: "" ,
  taxIssueReqDate: "",
}
export const INIT_SOLIST: SOList = {
  soId: 0,
  caseId: "",
  lineNum: 0,
  itemId: "", 
  itemName: "", 
  qty: 0,
  unitsPrice: 0, 
  supplyPrice: 0, 
  fee: "",
  salesPrice: 0, 
  ngSerialNum: "", 
  newSerialNum: "",
  exfacSerialNum: "",
  note: "",
  issueTypeId: "",
  inventLocationId: "", 
  salesPool: "",
  divMode: "",
  noPackingSlip: "No", 
  salesID: "",
}
export const INIT_CSR: CSR = {
  caseId: "",
  custInfo: INIT_CUSTINFO,
  prodInfo: INIT_PRODINFO,
  csInfo: INIT_CSINFO,
  serviceInfo: INIT_SERVICEINFO,
  soList: [{...INIT_SOLIST}]
}
export const INIT_VISITMEMO: VisitMemo = {
  branch: "",
  date: "",
  memo: "",
}
export const INIT_VISITDETAIL: VisitDetail = {
  caseId: "",
  handlingMethod: "",
  receivedDate: "",
  custName: "",
  categoryName: "",
  serialNum: "",
  prodDate: "",
  branch: "",
  address: "",
  receivedPerson: "",
  estimatedDateofVist: "",
  visitor: "",
  estimatedTime: "",
}
export const INIT_SEARCHDELIVERY: SearchDelivery = {
  classify: "customerName",
  text: "",
  setup: "2",
  startDate: "",
  endDate: "",
  sales: "0",
  branch: "",
  page: 1
}
export const INIT_SEARCHCALENDAR: SearchCalendar = {
  branch: "",
  name: "",
  date: moment().format("YYYY-MM-DD"),
}
export const SEND_MESSAGE: Message = {
  id: 0,
  title: "안녕하세요 인바디입니다.",
  phone: "",
  email: "",
  type: "normal",
  content: "",
  created: ""
}