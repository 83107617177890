import { Grid, Typography } from "@mui/material";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import React, { ReactElement } from "react";
import { HelpLink } from "style/theme";
import UserApi from "system/api/UserApi";
import { useLoadingDispatch } from "system/context/LoadingContext";

interface Props {
  onChange: (type: string) => void;
}

function ResetPassword(props: Props): ReactElement {
  const loading = useLoadingDispatch();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    if (!formData.get("userId")) {
      alert("ID is empty.");
      return;
    }
    formData.set("userId", (formData.get("userId") as string).trim());

    loading({ type: "LOADING" });
    UserApi.ResetPassword(formData)
      .then((res) => {
        if (res) {
          alert("Success to send reset email." + res);
        } else {
          alert("There is no email information for this ID.");
        }
      })
      .catch(() => {
        alert("Error to Send Request email.");
      })
      .finally(() => loading({ type: "COMPLETE" }));
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Reset Password</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" className="text-left">
            Enter your ID, and we'll send you instructions on how to reset your password via a registered email.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextBoxComponent
            id="userId"
            name="userId"
            type="text"
            cssClass="e-outline"
            placeholder="ID"
            style={{ height: "52px" }}
          ></TextBoxComponent>
        </Grid>
        <Grid item xs={12}>
          <ButtonComponent
            style={{ width: "100%", height: "52px", backgroundColor: "#91001e", color: "white" }}
            type="submit"
          >
            Send me reset Password
          </ButtonComponent>
        </Grid>
        <Grid item xs={12}>
          <HelpLink style={{ marginTop: "5px" }}>
            <Typography
              variant="body2"
              role="button"
              style={{ cursor: "pointer" }}
              onClick={() => props.onChange("signin")}
            >
              Back to sign-in
            </Typography>
          </HelpLink>
        </Grid>
      </Grid>
    </form>
  );
}

export default ResetPassword;
