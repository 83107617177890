import React from "react";
// import { Copyright } from "@mui/icons-material";
import { FooterStyle, SECONDARY } from "../../style/theme";
import { Container } from "react-bootstrap";

function Footer() {
  return (
    <FooterStyle>
      <Container className="py-2" style={{ fontSize: "10pt", color: SECONDARY, marginTop: "15px" }}>
        Copyright
        {/* <Copyright fontSize="small" />  */}
        {new Date().getFullYear()} InBody CO.,LTD. All right reserved
      </Container>
    </FooterStyle>
  );
}

export default Footer;
