import { Grid } from "@mui/material";
import { ChartComponent } from "@syncfusion/ej2-react-charts";
import {
  AxisModel,
  Category,
  DataLabel,
  Inject,
  Legend,
  SeriesCollectionDirective,
  SeriesDirective,
  SplineSeries,
  StripLine,
  Tooltip,
  TooltipSettingsModel,
} from "@syncfusion/ej2-react-charts/src";
import { useTranslation } from "react-i18next";
import { NoiseData } from "system/types";

interface SideNoiseGraphProps {
  underLine: number;
  data: NoiseData[];
}

function SideNoiseGraph({ underLine, data }: SideNoiseGraphProps) {
  const { t } = useTranslation();
  const primaryXAxis: AxisModel = {
    valueType: "Category",
    labelPlacement: "OnTicks",
    labelRotation: -45,
  };
  const primaryYAxis: AxisModel = {
    stripLines: [
      { start: underLine, size: 2, sizeType: "Pixel", dashArray: "4", color: "red", zIndex: "Over" },
      { start: -underLine, size: 2, sizeType: "Pixel", dashArray: "4", color: "red", zIndex: "Over" },
    ],
    minimum: -100,
    maximum: 100,
    interval: 10,
    labelFormat: "{value}mV",
  };
  const tooltip: TooltipSettingsModel = {
    enable: true,
    shared: true,
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {data.length === 0 ? (
          <>{t("no-data-30days")}</>
        ) : (
          <ChartComponent
            id="charts"
            primaryXAxis={primaryXAxis}
            primaryYAxis={primaryYAxis}
            tooltip={tooltip}
            height="1200px"
          >
            <Inject services={[SplineSeries, Legend, StripLine, Tooltip, DataLabel, Category]} />
            <SeriesCollectionDirective>
              <SeriesDirective
                dataSource={data}
                xName="datetime"
                yName="ra"
                fill="#C4C24A"
                width={2}
                type="Spline"
              ></SeriesDirective>
              <SeriesDirective
                dataSource={data}
                xName="datetime"
                yName="la"
                fill="#F6B52F"
                width={3}
                type="Spline"
              ></SeriesDirective>
            </SeriesCollectionDirective>
          </ChartComponent>
        )}
      </Grid>
    </Grid>
  );
}

export default SideNoiseGraph;
