import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  FilterSettingsModel,
  GridComponent,
  Group,
  Inject,
  Page,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { useEffect, useState } from "react";
import { LinkButton } from "style/theme";
import CRMApi from "system/api/CRMApi";
import { ErrorHandler } from "system/ApiService";
import { CSR, ServiceInfo } from "system/types";

interface CustomerSearchModalProps {
  open: boolean;
  onClose: () => void;
  data: CSR;
  setData: React.Dispatch<React.SetStateAction<any>>;
}

function CustomerSearchModal({ open, onClose, data, setData }: CustomerSearchModalProps) {
  const [companyList, setCompanyList] = useState<ServiceInfo[]>([]);

  useEffect(() => {
    CRMApi.GetCompanyInfo()
      .then((res) => {
        setCompanyList(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, []);

  const selectInfo = (select: ServiceInfo) => {
    const newData = { ...data };

    newData.serviceInfo = {
      ...newData.serviceInfo,
      companyName: select.companyName,
      companyAccount: select.companyAccount,
      companyNum: select.companyNum,
      companyPerson: select.companyPerson,
      email: select.email,
      state: select.state,
      city: select.city,
      street: select.street,
    };

    setData(newData);
    onClose();
  };

  const customTemplate = (args: any) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <LinkButton
          onClick={() => {
            selectInfo(args);
          }}
        >
          선택
        </LinkButton>
      </div>
    );
  };

  const FilterOptions: FilterSettingsModel = {
    type: "Menu",
    operators: {
      stringOperator: [
        { value: "contains", text: "Contains" },
        { value: "startsWith", text: "Starts With" },
        { value: "endsWith", text: "Ends With" },
        { value: "equal", text: "Equal" },
        { value: "notEqual", text: "Not Equal" },
      ],
    },
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={"lg"}>
      <DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "50px" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <GridComponent
              dataSource={companyList}
              allowPaging={true}
              pageSettings={{ pageSize: 10 }}
              allowFiltering={true}
              filterSettings={FilterOptions}
            >
              <ColumnsDirective>
                <ColumnDirective field="companyAccount" headerText="고객계정" width="100" textAlign="Center" />
                <ColumnDirective field="companyName" headerText="이름" width="150" textAlign="Center" />
                <ColumnDirective field="companyNum" headerText="사업자등록번호" width="150" textAlign="Center" />
                <ColumnDirective field="companyPerson" headerText="대표자명" width="150" textAlign="Center" />
                <ColumnDirective field="city" headerText="시군구" width="80" textAlign="Center" />
                <ColumnDirective field="state" headerText="시도" width="80" textAlign="Center" />
                <ColumnDirective width="50" template={customTemplate} allowFiltering={false} />
              </ColumnsDirective>
              <Inject services={[Sort, Page, Filter, Group]} />
            </GridComponent>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default CustomerSearchModal;
