import { Grid } from "@mui/material";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  FilterSettingsModel,
  GridComponent,
  Inject,
  Page,
} from "@syncfusion/ej2-react-grids";
import TitleDiv from "components/Common/TitleDiv";
import { useCallback, useEffect, useState } from "react";
import "style/table.css";
import { DarkButton, LightButton } from "style/theme";
import UserApi from "system/api/UserApi";
import { ErrorHandler } from "system/ApiService";
import { AuthorityType } from "system/Constants";
import { Branch, INIT_USER, pageType, User } from "system/types";
import AccountModal from "./AccountModal";

function AccountMainPage() {
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<User>(INIT_USER);
  const [list, setList] = useState<User[]>([]);
  const [type, setType] = useState<pageType>("View");
  const [branch, setBranch] = useState<Branch[]>([]);
  const FilterOptions: FilterSettingsModel = {
    type: "Menu",
  };

  useEffect(() => {
    UserApi.GetBranchList()
      .then((res) => {
        setBranch(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, []);

  const onLoad = useCallback(() => {
    UserApi.GetUserList()
      .then((res) => {
        const updatedList = res.map((user: User) => {
          const branchInfo = branch.find((item) => item.branchId === user.branch);
          return {
            ...user,
            branchName: branchInfo ? branchInfo.branchName : user.branch,
          };
        });
        setList(updatedList);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, [branch]);

  useEffect(() => {
    if (branch.length > 0) {
      onLoad();
    }
  }, [onLoad, branch]);

  const handleOpen = () => {
    setOpen(true);
    setData(INIT_USER);
    setType("Add");
  };

  const handleClose = () => {
    setOpen(false);
    setData(INIT_USER);
    onLoad();
  };

  const handleUpdate = (rowData: User) => {
    setData(rowData);
    setOpen(true);
    setType("Update");
  };

  const getAuthorityLabel = useCallback((key: string) => {
    const a = AuthorityType.find((item) => item.key === key);
    return a ? a.label : key;
  }, []);

  const getBranchLabel = useCallback(
    (key: string) => {
      const b = branch.find((item) => item.branchId === key);
      return b ? <>{b.branchName}</> : <>{key}</>;
    },
    [branch]
  );

  const CustomTemplate = useCallback((props: User) => {
    return (
      <>
        <LightButton onClick={() => handleUpdate(props)}>수정</LightButton>
      </>
    );
  }, []);

  const authFilter = (props: any) => {
    const a = AuthorityType.find((item) => item.key === props.authority);
    return a ? <>{a.label}</> : <>{props.authority}</>;
  };

  const authFilterSettings: Object = {
    type: "CheckBox",
    itemTemplate: authFilter,
  };

  const branchFilterSettings: Object = {
    type: "CheckBox",
    itemTemplate: (props: any) => {
      return <>{props.branchName}</>;
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="권한 관리" />
      </Grid>
      <Grid item xs={12} textAlign="end">
        <DarkButton onClick={handleOpen}>추가</DarkButton>
        <AccountModal open={open} onClose={handleClose} data={data} type={type} />
      </Grid>
      <Grid item xs={12}>
        <GridComponent dataSource={list} allowPaging={true} allowFiltering={true} filterSettings={FilterOptions}>
          <ColumnsDirective>
            <ColumnDirective field="id" headerText="ID" width="100" textAlign="Center" />
            <ColumnDirective field="name" headerText="이름" width="100" textAlign="Center" />
            <ColumnDirective
              field="authority"
              headerText="권한"
              width="100"
              textAlign="Center"
              template={(user: User) => getAuthorityLabel(user.authority)}
              filter={authFilterSettings}
            />
            <ColumnDirective
              field="branchName"
              headerText="지사"
              width="100"
              textAlign="Center"
              template={(user: User) => getBranchLabel(user.branch)}
              filter={branchFilterSettings}
            />
            <ColumnDirective width="100" textAlign="Center" template={CustomTemplate} />
          </ColumnsDirective>
          <Inject services={[Page, Filter]} />
        </GridComponent>
      </Grid>
    </Grid>
  );
}

export default AccountMainPage;
