import { useLocation } from "react-router-dom";
import { ObjType } from "system/types";

function useLocationStateMatch(props: string) {
  const location = useLocation<any>();
  if (location.state) {
    const k: ObjType = { ...location.state };
    if (k.hasOwnProperty(props)) {
      return k[props];
    }
  }
  return undefined;
}

export default useLocationStateMatch;
