import {
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  RadioGroup,
  Typography,
} from "@mui/material";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import MainTitleDiv from "components/Common/MainTitleDiv";
import TitleDiv from "components/Common/TitleDiv";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxCustom, DarkButton, LightButton, RadioCustom, RedButton, SearchDropDown } from "style/theme";
import ProductSearchApi from "system/api/ProductSearchApi";
import { ErrorHandler } from "system/ApiService";
import { EquipUnitTypestring } from "system/Constants";
import { useLoadingDispatch } from "system/context/LoadingContext";
import { useUserState } from "system/context/UserContext";
import { isChangeDateFormat } from "system/Helper";
import { EquipSetting } from "system/types";
import ChangePopUp from "./ChangePopUp";

interface ChangeModalProps {
  data: EquipSetting;
  nation: string;
  onLoad: () => void;
}

function ChangeModal({ data, nation, onLoad }: ChangeModalProps) {
  const { t } = useTranslation();
  const user = useUserState();
  const [open, setOpen] = useState<boolean>(false);
  const [popup, setPopup] = useState<boolean>(false);
  const [updatedData, setUpdatedData] = useState<EquipSetting>(data);
  const Loading = useLoadingDispatch();
  const EquipMeasureModeType = [
    { key: "ExpertMode", label: t("self-modeOFF") },
    { key: "SelfMode", label: t("self-modeON") },
  ];
  const EquipWeightInputType380580 = [
    { key: "ManualInput", label: t("manual-input-mode") },
    { key: "MeasureMode", label: t("weight-measurement-method") },
    { key: "ParallelMeasure", label: t("automatic-measurement-mode") },
  ];
  const EquipWeightInputType = [
    { key: "ManualInput", label: t("manual-input-mode") },
    { key: "MeasureMode", label: t("weight-measurement-method") },
  ];

  const Validation = (data: EquipSetting) => {
    if (data.useMode === "None" || data.unit === "None" || data.weightInputMode === "None") {
      alert(t("fill-blank-fields"));
      return false;
    } else if (
      (data.equip.startsWith("INBODY380") || data.equip.startsWith("INBODY580")) &&
      data.userManageMode === "None"
    ) {
      alert(t("fill-blank-fields"));
      return false;
    }
    return true;
  };

  useEffect(() => {
    setUpdatedData(data);
  }, [data]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (Validation(updatedData) && user.authority === "Admin") {
      Loading({ type: "LOADING" });
      ProductSearchApi.PutEquipSetting(updatedData.equipSerial, nation, updatedData)
        .then(() => {
          onLoad();
          setOpen(false);
          setPopup(true);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    }
  };

  const onChangeData = (e: any) => {
    setUpdatedData({ ...updatedData, [e.target.name]: e.target.value });
  };

  const onCheckedData = (e: any) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      resultSheetDetail: {
        ...prevData.resultSheetDetail,
        [e.target.name]: e.target.checked,
      },
    }));
  };

  const onDateChange = (args: any) => {
    const selectedDate = args.value;
    const formattedDateString = isChangeDateFormat(selectedDate);
    setUpdatedData({
      ...updatedData,
      datetime: formattedDateString,
    });
  };

  return (
    <div>
      <RedButton onClick={handleClickOpen}>{t("edit")}</RedButton>
      <Dialog open={open} onClose={handleClose} maxWidth="md" style={{ marginTop: "65px" }}>
        <DialogContent style={{ padding: "56px 65px" }}>
          <Grid container spacing={2} display="flex" alignItems="center">
            <Grid item xs={12}>
              <TitleDiv title={t("edit-settings")}></TitleDiv>
            </Grid>
            {/* 일반 설정 */}
            <Grid item xs={12}>
              <MainTitleDiv title={t("general-setting")}></MainTitleDiv>
              <Divider></Divider>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" width="150px">
                {t("country")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextBoxComponent
                style={{ width: "344px", height: "40px", borderColor: "#d9d9d9" }}
                value={updatedData.countryCodeISO}
                disabled
                cssClass="e-outline"
              ></TextBoxComponent>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" width="150px">
                {t("language")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextBoxComponent
                style={{ width: "344px", height: "40px", borderColor: "#d9d9d9" }}
                value={updatedData.language}
                disabled
                cssClass="e-outline"
              ></TextBoxComponent>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" width="150px">
                {t("units")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <SearchDropDown
                width="100%"
                cssClass="e-outline"
                value={updatedData.unit}
                onChange={onChangeData}
                name="unit"
                fields={{ text: "label", value: "key" }}
                dataSource={EquipUnitTypestring}
              ></SearchDropDown>
            </Grid>
            {/* 날짜 */}
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" width="150px">
                {t("dateandtime")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <DatePickerComponent
                width="100%"
                cssClass="e-outline"
                value={new Date(updatedData.datetime)}
                format="yyyy-MM-dd"
                onChange={onDateChange}
                showClearButton={false}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" width="150px">
                {t("test-mode")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <SearchDropDown
                width="100%"
                cssClass="e-outline"
                value={updatedData.useMode}
                onChange={onChangeData}
                name="useMode"
                fields={{ text: "label", value: "key" }}
                dataSource={EquipMeasureModeType}
              ></SearchDropDown>
            </Grid>
            {["INBODY380", "INBODY580", "INBODY380H", "INBODY580H"].includes(updatedData.equip) && (
              <>
                <Grid item xs={6}>
                  <Typography variant="body2" fontWeight="bold" width="150px">
                    {t("member-management-method")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormControl style={{ display: "flex" }}>
                    <RadioGroup
                      row
                      style={{ justifyContent: "space-between" }}
                      name="userManageMode"
                      value={updatedData.userManageMode}
                      onChange={onChangeData}
                    >
                      <FormControlLabel
                        style={{ marginRight: "105px" }}
                        value="UserId"
                        control={<RadioCustom />}
                        label={<span style={{ fontSize: "14px" }}>{t("id")}</span>}
                      />
                      <FormControlLabel
                        value="PhoneNumber"
                        control={<RadioCustom />}
                        label={<span style={{ fontSize: "14px" }}>{t("mobile-number")}</span>}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" width="150px">
                {t("weight-input-method")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <SearchDropDown
                width="100%"
                cssClass="e-outline"
                value={updatedData.weightInputMode}
                onChange={onChangeData}
                name="weightInputMode"
                fields={{ text: "label", value: "key" }}
                dataSource={
                  ["INBODY380", "INBODY580", "INBODY380H", "INBODY580H"].includes(updatedData.equip)
                    ? EquipWeightInputType380580
                    : EquipWeightInputType
                }
              ></SearchDropDown>
            </Grid>
            {/* 결과지 설정 */}
            <Grid item xs={12} style={{ marginTop: "40px" }}>
              <MainTitleDiv title={t("results-sheet-settings")}></MainTitleDiv>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12} display="flex">
              <Grid item xs={6} style={{ paddingTop: "9px" }}>
                <Typography variant="body2" fontWeight="bold" width="150px">
                  {t("results-sheet-type")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <CheckboxCustom
                        checked={updatedData.resultSheetDetail.isUseInBodyResultSheet}
                        onChange={onCheckedData}
                        name="isUseInBodyResultSheet"
                      />
                    }
                    label={<span style={{ fontSize: "14px" }}>{t("inBody-result-sheet")}</span>}
                  />
                  {updatedData.equip !== "INBODY270" && (
                    <FormControlLabel
                      control={
                        <CheckboxCustom
                          checked={updatedData.resultSheetDetail.isUseInBodyChildResultSheet}
                          onChange={onCheckedData}
                          name="isUseInBodyChildResultSheet"
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>{t("inBody-result-sheet-for-children")}</span>}
                    />
                  )}
                  {["INBODY970", "INBODY770"].includes(updatedData.equip) && (
                    <FormControlLabel
                      control={
                        <CheckboxCustom
                          checked={updatedData.resultSheetDetail.isUseWaterResultSheet}
                          onChange={onCheckedData}
                          name="isUseWaterResultSheet"
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>{t("body-water-result-sheet")}</span>}
                    />
                  )}
                  {updatedData.equip !== "INBODY270" && (
                    <FormControlLabel
                      control={
                        <CheckboxCustom
                          checked={updatedData.resultSheetDetail.isUseBodyTypeResultSheet}
                          onChange={onCheckedData}
                          name="isUseBodyTypeResultSheet"
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>{t("body-type-result-sheet")}</span>}
                    />
                  )}
                  {updatedData.equip === "INBODY970" && (
                    <>
                      <FormControlLabel
                        control={
                          <CheckboxCustom
                            checked={updatedData.resultSheetDetail.isUseResearchResultSheet}
                            onChange={onCheckedData}
                            name="isUseResearchResultSheet"
                          />
                        }
                        label={<span style={{ fontSize: "14px" }}>{t("research-result -sheet")}</span>}
                      />
                      <FormControlLabel
                        control={
                          <CheckboxCustom
                            checked={updatedData.resultSheetDetail.isUseCompareResultSheet}
                            onChange={onCheckedData}
                            name="isUseCompareResultSheet"
                          />
                        }
                        label={<span style={{ fontSize: "14px" }}>{t("comparison-result-sheet")}</span>}
                      />
                      <FormControlLabel
                        control={
                          <CheckboxCustom
                            checked={updatedData.resultSheetDetail.isUseOptionResultSheet}
                            onChange={onCheckedData}
                            name="isUseOptionResultSheet"
                          />
                        }
                        label={<span style={{ fontSize: "14px" }}>{t("evaluation-result-sheet")}</span>}
                      />
                      <FormControlLabel
                        control={
                          <CheckboxCustom
                            checked={updatedData.resultSheetDetail.isUseVisceralFatResultSheet}
                            onChange={onCheckedData}
                            name="isUseVisceralFatResultSheet"
                          />
                        }
                        label={<span style={{ fontSize: "14px" }}>{t("visceral-fat-result-sheet")}</span>}
                      />
                    </>
                  )}
                  {["INBODY380", "INBODY370S", "INBODY270"].includes(updatedData.equip) && (
                    <FormControlLabel
                      control={
                        <CheckboxCustom
                          checked={updatedData.resultSheetDetail.isUseThermalResultSheet}
                          onChange={onCheckedData}
                          name="isUseThermalResultSheet"
                        />
                      }
                      label={<span style={{ fontSize: "14px" }}>{t("thermal-result-sheet")}</span>}
                    />
                  )}
                </FormGroup>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" width="150px">
                {t("print-results-A4")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <SearchDropDown
                width="100%"
                cssClass="e-outline"
                value={updatedData.isResultSheetPaperA4}
                onChange={onChangeData}
                name="isResultSheetPaperA4"
                fields={{ text: "displayText", value: "value" }}
                dataSource={[
                  { displayText: t("use-on"), value: true },
                  { displayText: t("use-off"), value: false },
                ]}
              ></SearchDropDown>
            </Grid>
            <Grid item xs={12} display="flex">
              <Grid item xs={6} style={{ paddingTop: "9px" }}>
                <Typography variant="body2" fontWeight="bold" width="150px">
                  {t("automatic-printing")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <RadioGroup name="autoPrint" value={updatedData.autoPrint} onChange={onChangeData}>
                    <FormControlLabel
                      style={{ marginRight: "80px" }}
                      value="Page1AutoPrint"
                      control={<RadioCustom />}
                      label={<span style={{ fontSize: "14px" }}>{t("on-1Copy")}</span>}
                    />
                    <FormControlLabel
                      value="Page2AutoPrint"
                      control={<RadioCustom />}
                      label={<span style={{ fontSize: "14px" }}>{t("on-2Copies")}</span>}
                    />
                    <FormControlLabel
                      value="NotUse"
                      control={<RadioCustom />}
                      label={<span style={{ fontSize: "14px" }}>{t("unused")}</span>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign="end" style={{ marginTop: "70px" }}>
            <LightButton onClick={handleClose}>{t("cancel")}</LightButton>
            <DarkButton onClick={handleSave} style={{ marginBottom: "0px", marginLeft: "8px" }}>
              {t("save")}
            </DarkButton>
          </Grid>
        </DialogContent>
      </Dialog>
      {popup && <ChangePopUp popup={popup}></ChangePopUp>}
    </div>
  );
}

export default ChangeModal;
