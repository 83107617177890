import VisitList from "components/CRM/Calendar/VisitList";
import MultiDetailMain from "components/CRM/Detail/MultiDetailMain";
import MainPage from "components/Main/MainPage";
import EventReportList from "components/ProductSearch/QCData/EventReportList";
import Admin from "page/CRM/Admin";
import Delivery from "page/CRM/Delivery";
import Detail from "page/CRM/Detail";
import Report from "page/CRM/Report";
import Search from "page/CRM/Search";
import Content from "page/Layout/Content";
import Footer from "page/Layout/Footer";
import Header from "page/Layout/Header";
import * as Login from "page/Login/MainPage";
import NotFound from "page/NotFound";
import ProductDashboard from "page/ProductSearch/ProductDashboard";
import ProductSearchDetail from "page/ProductSearch/ProductSearchDetail";
import ProductSearchMain from "page/ProductSearch/ProductSearchMain";
import UserAccountMain from "page/User/UserAccountMain";
import UserProfileMain from "page/User/UserProfileMain";
import { ReactElement, useState } from "react";
import { Route, Switch } from "react-router-dom";
import UserRoute from "routes/UserRoute";
import { BodyWrap } from "style/theme";
import { useUserState } from "system/context/UserContext";
import { CommonRoutes, CRMRoutes, ProductSearchRoutes, UserRoutes } from "system/types/routeList";

function Routes(): ReactElement {
  return (
    <>
      <Route path={CommonRoutes.login}>
        <Login.MainPage></Login.MainPage>
      </Route>
      <UserRoute Component={ContentRoute} path={CommonRoutes.root}></UserRoute>
    </>
  );
}

export default Routes;

function ContentRoute(): ReactElement {
  const user = useUserState();
  const [open, setOpen] = useState<boolean>(true);
  return (
    <>
      {window.location.pathname.includes(CRMRoutes.view) ||
      window.location.pathname.includes(CRMRoutes.visit) ||
      window.location.pathname.includes(CRMRoutes.multi) ? (
        <div style={{ minHeight: "100%", position: "relative", padding: "2px", marginLeft: "20px" }}>
          <Content open={false}>
            <Route path={CRMRoutes.visit} component={VisitList} exact></Route>
            <Route path={CRMRoutes.detail} component={Detail} exact></Route>
            <Route path={CRMRoutes.multi} component={MultiDetailMain} exact></Route>
          </Content>
        </div>
      ) : (
        <>
          <BodyWrap>
            <Header open={open} setOpen={setOpen} />
            {/* Router */}
            <Content open={open}>
              <Switch>
                <Route path={CommonRoutes.root} exact component={MainPage}></Route>
                <Route path={UserRoutes.profile} component={UserProfileMain} exact></Route>

                {user.authority !== "" && (
                  <>
                    {/* user */}
                    {user.authority === "Admin" && (
                      <>
                        <Route path={UserRoutes.account} component={UserAccountMain} exact></Route>
                        <Route path={CRMRoutes.admin} component={Admin} exact></Route>
                      </>
                    )}

                    {/* product-search */}
                    <Route path={ProductSearchRoutes.root} component={ProductSearchMain} exact></Route>
                    <Route path={ProductSearchRoutes.detail} component={ProductSearchDetail} exact></Route>
                    <Route path={ProductSearchRoutes.eventReport} component={EventReportList} exact></Route>
                    {/* 미국법인용 */}
                    {user.branch === "HC005176" && (
                      <Route path={ProductSearchRoutes.dashboard} component={ProductDashboard} exact></Route>
                    )}

                    {/* CS Search */}
                    {!["Engineer", "User"].includes(user.authority) && (
                      <>
                        <Route path={CRMRoutes.search} component={Search} exact></Route>
                        <Route path={CRMRoutes.update} component={Report} exact></Route>
                        <Route path={CRMRoutes.detail} component={Detail} exact></Route>
                        <Route path={CRMRoutes.delivery} component={Delivery} exact></Route>
                      </>
                    )}
                  </>
                )}

                <Route path={"*"} component={NotFound}></Route>
              </Switch>
            </Content>
            <br />
          </BodyWrap>
          <Footer></Footer>
        </>
      )}
    </>
  );
}
