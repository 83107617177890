import React, { ReactElement } from "react";
import { Redirect, Route, RouteComponentProps, useLocation } from "react-router-dom";
import UserApi from "system/api/UserApi";
import useLocationStateMatch from "system/context/useLocationStateMatch";
import { ObjType } from "system/types";
import { CommonRoutes } from "system/types/routeList";

interface Props {
  Component: React.FC<RouteComponentProps>;
  path?: string;
  exact?: boolean;
}

export function UserRoute({ Component, path, exact = false }: Props): ReactElement {
  const location = useLocation<ObjType>();
  const requestedPath = useLocationStateMatch("requestedPath");
  const isUser = UserApi.GetToken() !== "";

  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) =>
        isUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: CommonRoutes.login,
              search: location.search,
              state: {
                ...location.state,
                requestedPath: requestedPath || location.pathname,
              },
            }}
          />
        )
      }
    ></Route>
  );
}

export default UserRoute;
