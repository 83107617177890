import { Grid, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import { CalendarComponent, RenderDayCellEventArgs } from "@syncfusion/ej2-react-calendars";
import moment from "moment";
import { useEffect, useState } from "react";
import CRMApi from "system/api/CRMApi";
import { ErrorHandler } from "system/ApiService";
import { useUserState } from "system/context/UserContext";
import { CalendarList, INIT_SEARCHCALENDAR, SearchCalendar } from "system/types";
import { CRMRoutes } from "system/types/routeList";
import { BodyTableCell, TitleTableCell } from "../../Common/SignBox";

function Calendar() {
  const user = useUserState();
  const [search, setSearch] = useState<SearchCalendar>(INIT_SEARCHCALENDAR);
  const [list, setList] = useState<CalendarList[]>([]);

  const handleClickBO = (branchName: string) => {
    const queryParams = new URLSearchParams({
      branch: branchName,
      date: search.date,
    }).toString();

    const url = `${CRMRoutes.visit}?${queryParams}`;
    window.open(url, "Visit_BO_List", "location=no,status=no,scrollbars=yes,width=1600px,height=800px");
  };

  const disabledDate = (args: RenderDayCellEventArgs) => {
    if ((args.date as Date).getDay() === 0 && args.element) {
      args.isDisabled = true;
      const spanElement = args.element.querySelector("span");
      if (spanElement) {
        spanElement.style.color = "red";
      }
    } else if ((args.date as Date).getDay() === 6 && args.element) {
      args.isDisabled = true;
      const spanElement = args.element.querySelector("span");
      if (spanElement) {
        spanElement.style.color = "blue";
      }
    }
  };

  useEffect(() => {
    CRMApi.GetCalendar({ ...search, branch: user.branch })
      .then((res) => {
        setList(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const changeDate = (args: any) => {
    setSearch({ ...search, date: moment(args.value).format("YYYY-MM-DD") });
  };
  const onChangeSearch = (e: any) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  return (
    <Grid container spacing={2} style={{ backgroundColor: "white", marginBottom: "5px" }}>
      <Grid item xs={12}>
        <CalendarComponent onChange={changeDate} value={new Date(search.date)} renderDayCell={disabledDate} />
      </Grid>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "space-around" }}>
        <RadioButtonComponent
          name="name"
          value=""
          checked={search.name === ""}
          onChange={onChangeSearch}
          label="전체보기"
        ></RadioButtonComponent>
        <RadioButtonComponent
          name="name"
          value={user.name}
          checked={search.name !== ""}
          onChange={onChangeSearch}
          label="나만보기"
        ></RadioButtonComponent>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">{search.date} 방문예정</Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TitleTableCell></TitleTableCell>
                <TitleTableCell>인바디</TitleTableCell>
                <TitleTableCell>혈압계</TitleTableCell>
                <TitleTableCell>납품</TitleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((l) => (
                <TableRow key={l.branchName}>
                  <BodyTableCell
                    onClick={() => handleClickBO(l.branchName)}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    {l.branchName.split(" ")[1]}
                  </BodyTableCell>
                  <BodyTableCell>{l.i_count}</BodyTableCell>
                  <BodyTableCell>{l.b_count}</BodyTableCell>
                  <BodyTableCell>{l.r_count}</BodyTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default Calendar;
