import { Grid } from "@mui/material";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import "style/form.css";
import { FormButton } from "style/theme";
import ERPApi from "system/api/ERPApi";
import { ErrorHandler } from "system/ApiService";
import { useLoadingDispatch } from "system/context/LoadingContext";
import { useUserState } from "system/context/UserContext";
import PrintButtonGroup from "../Report/BtnGroupPrint";
import DetailForm from "./DetailForm";

interface DetailMainParams {
  id: string;
}

function DetailMain() {
  const { id } = useParams<DetailMainParams>();
  const user = useUserState();
  const printRef = useRef<HTMLDivElement>(null);
  const Loading = useLoadingDispatch();

  const sendERP = () => {
    Loading({ type: "LOADING" });
    ERPApi.InsertCSRtoERP(id)
      .then((res) => {
        alert("ERP 전송 완료");
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <ReactToPrint trigger={() => <FormButton>인쇄</FormButton>} content={() => printRef.current} />
        {["Admin", "HeadA"].includes(user.authority) && (
          <FormButton id="erp_btn" onClick={sendERP} style={{ marginLeft: "5px" }}>
            ERP 전송
          </FormButton>
        )}
      </Grid>
      {/* 문서 출력 */}
      <Grid item xs={6}>
        <PrintButtonGroup caseId={id}></PrintButtonGroup>
      </Grid>
      <Grid item xs={12} ref={printRef}>
        <DetailForm caseId={id}></DetailForm>
      </Grid>
    </Grid>
  );
}

export default DetailMain;
