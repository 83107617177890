import { Grid } from "@mui/material";
import SubTitleDiv from "components/Common/SubTitleDiv";
import TitleDiv from "components/Common/TitleDiv";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DarkButton, SearchBox, SearchDropDown } from "style/theme";
import { NationType } from "system/Constants";
import { useUserState } from "system/context/UserContext";
import { ProductSearchRoutes } from "system/types/routeList";

function PSMainPage() {
  const user = useUserState();
  const history = useHistory();
  const { t } = useTranslation();
  const [serial, setSerial] = useState<string>("");
  const [nation, setNation] = useState<string>(user.branch === "HC005176" ? "en" : "kr");

  const onSearch = () => {
    if (serial.length < 2) {
      alert(t("enter-serial"));
    } else {
      history.push(`${ProductSearchRoutes.root}/detail/${nation}/${serial}`);
    }
  };

  const changeSerial = (args: any) => {
    setSerial(args.target.value);
  };

  const changeNation = (args: any) => {
    setNation(args.target.value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title={t("product-search")}></TitleDiv>
      </Grid>
      <br />
      <Grid item xs={12} display="flex" alignItems="center" style={{ margin: "5px" }}>
        <Grid item xs={1} flex={1}>
          <SubTitleDiv title={t("country")}></SubTitleDiv>
        </Grid>
        <Grid item xs={4} flex={2} style={{ marginLeft: "20px" }}>
          <SearchDropDown
            onChange={changeNation}
            cssClass="e-outline"
            width="100%"
            value={nation}
            name="unit"
            fields={{ text: "label", value: "key" }}
            dataSource={user.branch === "HC005176" ? [NationType[1]] : NationType}
          ></SearchDropDown>
        </Grid>
      </Grid>
      <Grid item xs={12} display="flex" alignItems="center" style={{ margin: "5px" }}>
        <Grid item xs={1} flex={1}>
          <SubTitleDiv title={t("serial-number")}></SubTitleDiv>
        </Grid>
        <Grid item xs={4} flex={2} style={{ marginLeft: "20px" }}>
          <SearchBox onChange={changeSerial} placeholder={t("input-serial-number")} cssClass="e-outline"></SearchBox>
        </Grid>
        <Grid item xs={1} flex={1} textAlign="center">
          <DarkButton onClick={onSearch}>{t("search")}</DarkButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PSMainPage;
