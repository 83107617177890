import { AxiosError } from "axios";
import HttpClient from "system/http-client";

const client = new HttpClient();
export default client;
// export const UserApi = new _UserApi(client);


export function ErrorHandler(error: AxiosError): string {
  let msg = "";
  if (error.response) {
    msg += error.response.data;
    if (msg === "" || msg === "[object Object]") {
      //여전히 빈 상태라면...
      switch (error.response.status) {
        case 401:
          msg = "Session Error";
          window.location.replace("/");
          break;
        case 400: //Bad Request
          msg = "Wrong Data Has Been Entered";
          break;
        case 403: //Forbidden
          msg = "Cannot Access";
          break;
        case 404: //NotFound
          msg = "No Data to Show";
          break;
        case 405: //method not allowd
          msg = "405; Cannot Access";
          break;
        case 500:
          msg = "Server Error";
          break;
        default:
          msg = "Unknown Error. Please Contact the Administrator";
          break;
      }
    }
  } else if (error.request) {
    msg += "No Response from the Server";
  }
  return msg;
}