import { FormButton, FormTitle } from "style/theme";
import { useUserState } from "system/context/UserContext";
import { Branch, Contact, CSR, Message, SEND_MESSAGE } from "system/types";

interface ClipBoardButtonProps {
  data: CSR;
  branchList: Branch[];
  setData: React.Dispatch<React.SetStateAction<any>>;
  setMsg: (message: Message) => void;
}

function ClipBoardButton({ data, branchList, setData, setMsg }: ClipBoardButtonProps) {
  const user = useUserState();
  const onCopyCSForm = () => {
    const formattedText = `담당지사: ${
      branchList.find((x) => x.branchId === data.custInfo.branch)?.branchName || ""
    }\n주소: ${data.custInfo.state + data.custInfo.city + data.custInfo.street}\n상호: ${
      data.custInfo.custName
    }\n제품명: ${data.prodInfo.categoryName}\nSN: ${data.prodInfo.serialNum}\n생산일: ${
      data.prodInfo.prodDate
    }\n판매일: ${data.prodInfo.salesDate}\n납품일: ${data.prodInfo.reqSetupDate}\n접수일: ${
      data.csInfo.receivedDate
    }\n생산자: ${data.prodInfo.productionPerson}\n측정횟수: ${data.csInfo.countCheck}\nDigital: ${
      data.csInfo.digital
    }\n접수내용: ${data.csInfo.caseNote}\n처리내용: ${data.csInfo.serviceNote}`;

    navigator.clipboard
      .writeText(formattedText)
      .then(() => {
        console.log("Text copied to clipboard:", formattedText);
        alert("클립보드에 복사되었습니다.");
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
        // You can also show an error message to the user
      });
  };
  const onCopyCSInfo = () => {
    const formattedText = `상호명: ${data.custInfo.custName}\n주소: ${
      data.custInfo.state + data.custInfo.city + data.custInfo.street
    }\n전화번호: ${data.custInfo.contactInfo[0].locator}\n제품명: ${data.prodInfo.categoryName}\nS/N: ${
      data.prodInfo.serialNum
    }\n접수내역: ${data.csInfo.caseNote}`;

    navigator.clipboard
      .writeText(formattedText)
      .then(() => {
        console.log("Text copied to clipboard:", formattedText);
        alert("클립보드에 복사되었습니다.");
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
        // You can also show an error message to the user
      });
  };

  const onCompleteCSForm = () => {
    const copyText = `*상담문의\n-이메일주소: `;
    const newData = { ...data };

    newData.csInfo = {
      ...newData.csInfo,
      caseNote: copyText,
    };

    setData(newData);
  };

  const onCompleteForm = () => {
    const formattedText = `안녕하세요 인바디입니다.\n문의하신 내용에 대해 답변드립니다.\n----------------------------\n문의내용:\n${data.csInfo.caseNote}\n----------------------------\n${data.csInfo.serviceNote}`;
    let phone: string = "";

    if (data.custInfo.contactInfo.length > 0) {
      // Find the primary contact
      const primaryContact = data.custInfo.contactInfo.find((contact: Contact) => contact.isPrimary === "Yes");

      if (primaryContact) {
        phone = primaryContact.locator;
      } else {
        phone = data.custInfo.contactInfo[0].locator;
      }
    }

    const message: Message = {
      ...SEND_MESSAGE,
      type: "normal",
      phone: phone,
      content: formattedText,
    };
    setMsg(message);
  };
  return (
    <>
      <FormTitle>도구모음</FormTitle>
      <FormButton onClick={onCopyCSForm}>불량보고: CS양식 복사</FormButton>
      <FormButton onClick={onCopyCSInfo}>CS기본정보 복사</FormButton>
      {["Admin", "HeadA", "HeadB"].includes(user.authority) && (
        <>
          <FormButton onClick={onCompleteCSForm}>CS양식 자동완성</FormButton>
          <FormButton onClick={onCompleteForm}>문자양식 자동완성</FormButton>
        </>
      )}
    </>
  );
}

export default ClipBoardButton;
