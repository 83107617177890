import { Grid, Table, TableBody, TableContainer, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  BodyTableCell,
  FormButton,
  FormTextBox,
  LightButton,
  LinkButton,
  TextButton,
  TitleTableCell,
} from "style/theme";
import CRMApi from "system/api/CRMApi";
import { ErrorHandler } from "system/ApiService";
import { HandlingMethod } from "system/CRMConstants";
import { INIT_VISITDETAIL, INIT_VISITMEMO, pageType, Visit, VisitDetail, VisitMemo } from "system/types";
import { CRMRoutes } from "system/types/routeList";

function VisitList() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const branch = urlParams.get("branch");
  const date = urlParams.get("date");
  const [list, setList] = useState<Visit>({ inbody: [], bpbio: [], memo: "" });
  const [memo, setMemo] = useState<VisitMemo>(INIT_VISITMEMO);

  const [select, setSelect] = useState<VisitDetail>(INIT_VISITDETAIL);
  const [type, setType] = useState<pageType>("View");

  useEffect(() => {
    if (branch && date) {
      CRMApi.GetVisitList({ branch: branch, date: date, name: "" })
        .then((res) => {
          setList(res);
          setMemo({ ...memo, branch: branch, date: date, memo: res.memo });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch, date]);

  const handleViewClick = (caseId: string) => {
    window.open(
      `${window.location.origin}${CRMRoutes.view}/${caseId}`,
      "_blank",
      "location=no,status=no,scrollbars=yes,width=1100px,height=1050px"
    );
  };

  const handleUpdateClick = (caseId: string) => {
    window.opener.location.href = `${window.location.origin}${CRMRoutes.report}/${caseId}`;
  };

  const handlePrint = () => {
    let idQueryString = list.inbody.map((i) => `id=${encodeURIComponent(i.caseId)}`).join("&");
    idQueryString += list.bpbio.map((i) => `&id=${encodeURIComponent(i.caseId)}`).join("&");

    window.open(
      `${window.location.origin}${CRMRoutes.multi}?${idQueryString}`,
      "Multi_Print_Page",
      "status=no,scrollbars=yes,width=1100px,height=1050px"
    );
  };

  const onSaveMemo = (e: any) => {
    CRMApi.UpsertVisitMemo(memo)
      .then(() => {
        alert("저장되었습니다.");
        setList({ ...list, memo: memo.memo });
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  };

  const onChangeMemo = (e: any) => {
    setMemo({
      ...memo,
      memo: e.target.value,
    });
  };

  const onChangeData = (item: VisitDetail) => {
    setType("Update");
    setSelect(item);
  };

  const onSaveData = (updatedItem: VisitDetail) => {
    setType("View");
    if (select.caseId === updatedItem.caseId) {
      CRMApi.UpsertVisitDetail(select)
        .then(() => {
          const updatedList = {
            inbody: list.inbody.map((item) => (item.caseId === updatedItem.caseId ? select : item)),
            bpbio: list.bpbio.map((item) => (item.caseId === updatedItem.caseId ? select : item)),
            memo: "",
          };
          setList(updatedList);
          setSelect(INIT_VISITDETAIL);
          alert("수정되었습니다.");
        })
        .catch(() => {
          alert("변경에 실패했습니다.");
        });
    }
  };

  const onChangeVisit = (e: any) => {
    setSelect({ ...select, [e.target.name]: e.target.value });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        {list.inbody.length + list.bpbio.length >= 1 && (
          <LightButton id="download_btn" onClick={handlePrint}>
            인쇄
          </LightButton>
        )}
      </Grid>
      <Grid item xs={8} display="flex" justifyContent="flex-end" alignItems="center">
        <textarea
          name="memo"
          value={memo.memo}
          style={{ width: "50%", minHeight: "50px", resize: "vertical" }}
          onChange={onChangeMemo}
          placeholder="Memo"
        ></textarea>
        <TextButton onClick={onSaveMemo}>저장</TextButton>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">인바디 {date} 방문예정건</Typography>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: "0px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TitleTableCell align="center">번호</TitleTableCell>
                <TitleTableCell align="center">처리방법</TitleTableCell>
                <TitleTableCell align="center">접수일</TitleTableCell>
                <TitleTableCell align="center">상호</TitleTableCell>
                <TitleTableCell align="center">제품명</TitleTableCell>
                <TitleTableCell align="center">S/N</TitleTableCell>
                <TitleTableCell align="center">생산일</TitleTableCell>
                <TitleTableCell align="center">담당지사</TitleTableCell>
                <TitleTableCell align="center">주소</TitleTableCell>
                <TitleTableCell align="center">접수자</TitleTableCell>
                <TitleTableCell align="center">방문예정일</TitleTableCell>
                <TitleTableCell align="center">상세보기</TitleTableCell>
                <TitleTableCell align="center" width={"100px"}>
                  방문자
                </TitleTableCell>
                <TitleTableCell align="center" width={"100px"}>
                  방문메모
                </TitleTableCell>
                <TitleTableCell align="center"></TitleTableCell>
              </TableRow>
              {list.inbody.length >= 1 &&
                list.inbody.map((item) => (
                  <TableRow key={item.caseId}>
                    <BodyTableCell align="center">{item.caseId}</BodyTableCell>
                    <BodyTableCell align="center">
                      {HandlingMethod.find((x) => x.label === item.handlingMethod)?.key}
                    </BodyTableCell>
                    <BodyTableCell align="center">{item.receivedDate}</BodyTableCell>
                    <BodyTableCell align="center">{item.custName}</BodyTableCell>
                    <BodyTableCell align="center">{item.categoryName}</BodyTableCell>
                    <BodyTableCell align="center">{item.serialNum}</BodyTableCell>
                    <BodyTableCell align="center">{item.prodDate}</BodyTableCell>
                    <BodyTableCell align="center">{item.branch}</BodyTableCell>
                    <BodyTableCell align="center">{item.address}</BodyTableCell>
                    <BodyTableCell align="center">{item.receivedPerson}</BodyTableCell>
                    <BodyTableCell align="center">{item.estimatedDateofVist}</BodyTableCell>
                    <BodyTableCell align="center" style={{ display: "flex", flexDirection: "column" }}>
                      <LinkButton onClick={() => handleViewClick(item.caseId)}>보기</LinkButton>
                      <LinkButton onClick={() => handleUpdateClick(item.caseId)}>수정</LinkButton>
                    </BodyTableCell>
                    {type === "Update" && select?.caseId === item.caseId ? (
                      <>
                        <BodyTableCell align="center">
                          <FormTextBox
                            name="visitor"
                            cssClass="e-outline e-small"
                            value={item.visitor}
                            onChange={onChangeVisit}
                          ></FormTextBox>
                        </BodyTableCell>
                        <BodyTableCell align="center">
                          <FormTextBox
                            name="estimatedTime"
                            cssClass="e-outline e-small"
                            value={item.estimatedTime}
                            onChange={onChangeVisit}
                          ></FormTextBox>
                        </BodyTableCell>
                        <BodyTableCell align="center">
                          <FormButton onClick={() => onSaveData(item)}>저장</FormButton>
                        </BodyTableCell>
                      </>
                    ) : (
                      <>
                        <BodyTableCell align="center">{item.visitor}</BodyTableCell>
                        <BodyTableCell align="center">
                          {item.estimatedTime.replace(/(\d{2})(\d{2})/, "$1:$2")}
                        </BodyTableCell>
                        <BodyTableCell align="center">
                          <FormButton onClick={() => onChangeData(item)}>수정</FormButton>
                        </BodyTableCell>
                      </>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">혈압계 {date} 방문예정건</Typography>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: "0px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TitleTableCell align="center">번호</TitleTableCell>
                <TitleTableCell align="center">처리방법</TitleTableCell>
                <TitleTableCell align="center">접수일</TitleTableCell>
                <TitleTableCell align="center">상호</TitleTableCell>
                <TitleTableCell align="center">제품명</TitleTableCell>
                <TitleTableCell align="center">S/N</TitleTableCell>
                <TitleTableCell align="center">생산일</TitleTableCell>
                <TitleTableCell align="center">담당지사</TitleTableCell>
                <TitleTableCell align="center">주소</TitleTableCell>
                <TitleTableCell align="center">접수자</TitleTableCell>
                <TitleTableCell align="center">방문예정일</TitleTableCell>
                <TitleTableCell align="center">상세보기</TitleTableCell>
                <TitleTableCell align="center" width={"100px"}>
                  방문자
                </TitleTableCell>
                <TitleTableCell align="center" width={"100px"}>
                  방문메모
                </TitleTableCell>
                <TitleTableCell align="center"></TitleTableCell>
              </TableRow>
              {list.bpbio.length >= 1 &&
                list.bpbio.map((item) => (
                  <TableRow key={item.caseId}>
                    <BodyTableCell align="center">{item.caseId}</BodyTableCell>
                    <BodyTableCell align="center">
                      {HandlingMethod.find((x) => x.label === item.handlingMethod)?.key}
                    </BodyTableCell>
                    <BodyTableCell align="center">{item.receivedDate}</BodyTableCell>
                    <BodyTableCell align="center">{item.custName}</BodyTableCell>
                    <BodyTableCell align="center">{item.categoryName}</BodyTableCell>
                    <BodyTableCell align="center">{item.serialNum}</BodyTableCell>
                    <BodyTableCell align="center">{item.prodDate}</BodyTableCell>
                    <BodyTableCell align="center">{item.branch}</BodyTableCell>
                    <BodyTableCell align="center">{item.address}</BodyTableCell>
                    <BodyTableCell align="center">{item.receivedPerson}</BodyTableCell>
                    <BodyTableCell align="center">{item.estimatedDateofVist}</BodyTableCell>
                    <BodyTableCell align="center" style={{ display: "flex", flexDirection: "column" }}>
                      <LinkButton onClick={() => handleViewClick(item.caseId)}>보기</LinkButton>
                      <LinkButton onClick={() => handleUpdateClick(item.caseId)}>수정</LinkButton>
                    </BodyTableCell>
                    {type === "Update" && select.caseId === item.caseId ? (
                      <>
                        <BodyTableCell align="center">
                          <FormTextBox
                            name="visitor"
                            cssClass="e-outline e-small"
                            value={item.visitor}
                            onChange={onChangeVisit}
                          ></FormTextBox>
                        </BodyTableCell>
                        <BodyTableCell align="center">
                          <FormTextBox
                            name="estimatedTime"
                            cssClass="e-outline e-small"
                            value={item.estimatedTime}
                            onChange={onChangeVisit}
                          ></FormTextBox>
                        </BodyTableCell>
                        <BodyTableCell align="center">
                          <FormButton onClick={() => onSaveData(item)}>저장</FormButton>
                        </BodyTableCell>
                      </>
                    ) : (
                      <>
                        <BodyTableCell align="center">{item.visitor}</BodyTableCell>
                        <BodyTableCell align="center">
                          {item.estimatedTime.replace(/(\d{2})(\d{2})/, "$1:$2")}
                        </BodyTableCell>
                        <BodyTableCell align="center">
                          <FormButton onClick={() => onChangeData(item)}>수정</FormButton>
                        </BodyTableCell>
                      </>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default VisitList;
