import { Dialog, DialogContent, Divider, Grid } from "@mui/material";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import MainTitleDiv from "components/Common/MainTitleDiv";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DarkButton, FormButton, LightButton } from "style/theme";
import ProductSearchApi from "system/api/ProductSearchApi";
import { ErrorHandler } from "system/ApiService";
import { EquipURL } from "system/types";

interface ChangeURLProps {
  serial: string;
  originUrl: string;
  nation: string;
}

function ChangeURL({ serial, originUrl, nation }: ChangeURLProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<EquipURL>({ equipSerial: serial, originUrl: originUrl, changeUrl: originUrl });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (originUrl !== data.changeUrl) {
      ProductSearchApi.PutEquipURL(data, nation)
        .then(() => {
          setOpen(false);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    }
    alert(t("no-change"));
  };

  const onChangeURL = (e: any) => {
    setData({ ...data, changeUrl: e.target.value });
  };

  return (
    <div>
      <FormButton onClick={handleClickOpen} style={{ margin: "0px" }}>
        {t("edit")}
      </FormButton>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent style={{ padding: "56px 65px" }}>
          <Grid container spacing={2} display="flex" alignItems="center">
            <Grid item xs={12}>
              <MainTitleDiv title={t("edit-url")}></MainTitleDiv>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12}>
              <TextBoxComponent
                value={data.changeUrl}
                width="100%"
                cssClass="e-outline"
                onChange={onChangeURL}
              ></TextBoxComponent>
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign="end" style={{ marginTop: "70px" }}>
            <LightButton onClick={handleClose}>{t("cancel")}</LightButton>
            <DarkButton onClick={handleSave} style={{ marginBottom: "0px", marginLeft: "8px" }}>
              {t("save")}
            </DarkButton>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ChangeURL;
