import { Grid, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "style/form.css";
import { BodyTableCell, LinkButton, YellowTitleTableCell } from "style/theme";
import CRMApi from "system/api/CRMApi";
import { ErrorHandler } from "system/ApiService";
import { CallResult, HandlingMethod, ResultForVisit, ServiceBillType } from "system/CRMConstants";
import { ASRecord, CSR } from "system/types";
import { CRMRoutes } from "system/types/routeList";

interface ReportASRecordProps {
  data: CSR;
}

function ReportASRecord({ data }: ReportASRecordProps) {
  const history = useHistory();
  const [record, setRecord] = useState<ASRecord[]>([]);

  useEffect(() => {
    if (data.prodInfo.prodId !== 0) {
      CRMApi.GetCSRHistory(data)
        .then((res) => {
          setRecord(res);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    } else setRecord([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.prodInfo.prodId]);

  const handleViewClick = (caseId: string) => {
    window.open(
      `${CRMRoutes.view}/${caseId}`,
      "_blank",
      "menubar=no,toolbar=no,location=no,directories=no,status=no,scrollbars=yes,resizable=yes,width=1200px,height=1050px,left=0,top=0"
    );
  };

  const handleSearch = (caseId: string) => {
    history.push(`${CRMRoutes.report}/${caseId}`);
  };

  return (
    <Grid item xs={12}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <YellowTitleTableCell style={{ width: "80px" }} align="center">
                AS이력
              </YellowTitleTableCell>
            </TableRow>
            <TableRow>
              <YellowTitleTableCell style={{ width: "80px" }} align="center">
                처리방법
              </YellowTitleTableCell>
              <YellowTitleTableCell align="center">접수일</YellowTitleTableCell>
              <YellowTitleTableCell align="center">접수자</YellowTitleTableCell>
              <YellowTitleTableCell align="center">불량구분대분류</YellowTitleTableCell>
              <YellowTitleTableCell align="center">불량구분소분류</YellowTitleTableCell>
              <YellowTitleTableCell align="center">처리일</YellowTitleTableCell>
              <YellowTitleTableCell align="center">처리자</YellowTitleTableCell>
              <YellowTitleTableCell align="center">처리결과대분류</YellowTitleTableCell>
              <YellowTitleTableCell align="center">처리결과소분류</YellowTitleTableCell>
              <YellowTitleTableCell align="center">방문처리결과</YellowTitleTableCell>
              <YellowTitleTableCell align="center">전화결과</YellowTitleTableCell>
              <YellowTitleTableCell align="center">불량시점</YellowTitleTableCell>
              <YellowTitleTableCell align="center">상세보기</YellowTitleTableCell>
            </TableRow>
            {record.map((r: ASRecord) => (
              <TableRow key={r.caseId}>
                <BodyTableCell align="center">
                  {HandlingMethod.find((x) => x.label === r.handlingMethod)?.key}
                </BodyTableCell>
                <BodyTableCell align="center">{r.receivedDate}</BodyTableCell>
                <BodyTableCell align="center">{r.receivedPerson}</BodyTableCell>
                <BodyTableCell align="center">{r.ngCategoryId}</BodyTableCell>
                <BodyTableCell align="center">{r.ngCategoryDetailId}</BodyTableCell>
                <BodyTableCell align="center">{r.completedDate}</BodyTableCell>
                <BodyTableCell align="center">{r.completedPerson}</BodyTableCell>
                <BodyTableCell align="center">{r.resultCategoryId}</BodyTableCell>
                <BodyTableCell align="center">{r.resultCategoryDetailId}</BodyTableCell>
                <BodyTableCell align="center">
                  {ResultForVisit.find((x) => x.label === r.resultForVisit)?.key}
                </BodyTableCell>
                <BodyTableCell align="center">{CallResult.find((x) => x.label === r.callResult)?.key}</BodyTableCell>
                <BodyTableCell align="center">
                  {ServiceBillType.find((x) => x.label === r.serviceBillType)?.key}
                </BodyTableCell>
                <BodyTableCell align="center">
                  <LinkButton onClick={() => handleViewClick(r.caseId)}>보기</LinkButton>
                  <LinkButton onClick={() => handleSearch(r.caseId)}>수정</LinkButton>
                </BodyTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default ReportASRecord;
