// store.ts
import create from 'zustand';
import { SearchCRM, INIT_CRMSEARCH } from 'system/types';

interface SearchState {
  searchForm: SearchCRM;
  setSearchForm: (form: Partial<SearchCRM>) => void;
  resetSearchForm: (initialForm: SearchCRM) => void;
}

const useSearchStore = create<SearchState>((set) => ({
  searchForm: INIT_CRMSEARCH, // 기본 초기값 설정
  setSearchForm: (form) => set((state) => ({
    searchForm: { 
      ...state.searchForm, 
      ...form,
      
    }
  })),
  resetSearchForm: (initialForm) => set({ searchForm: initialForm }), // 상태 초기화 함수
}));

export default useSearchStore;
