import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { BodyTableCell, LinkButton, TitleTableCell } from "style/theme";
import CRMApi from "system/api/CRMApi";
import { ErrorHandler } from "system/ApiService";
import { useLoadingDispatch } from "system/context/LoadingContext";
import { useUserState } from "system/context/UserContext";
import { CSRSearchList, INIT_CSR, INIT_CUSTINFO, INIT_PRODINFO } from "system/types";
import moment from "moment";

interface InfoDetailModalProps {
  open: boolean;
  onClose: () => void;
  custList: CSRSearchList[];
  setData: React.Dispatch<React.SetStateAction<any>>;
  setFlag: React.Dispatch<React.SetStateAction<any>>;
}

function InfoDetailModal({ open, onClose, custList, setData, setFlag }: InfoDetailModalProps) {
  const user = useUserState();
  const Loading = useLoadingDispatch();

  const selectInfo = (item: CSRSearchList) => {
    // 조회
    Loading({ type: "LOADING" });
    CRMApi.GetCustProdInfo(item)
      .then((res) => {
        setData({
          ...INIT_CSR,
          custInfo: res.custInfo || INIT_CUSTINFO,
          prodInfo: res.prodInfo || INIT_PRODINFO,
          csInfo: {
            ...INIT_CSR.csInfo,
            receivedPerson: user.name,
            csReceivedTime: moment().format("HH:mm"),
          },
        });
        setFlag(true);
        onClose();
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={"lg"}>
      <DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "50px" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TitleTableCell align="center">상호명</TitleTableCell>
                    <TitleTableCell align="center">전화번호</TitleTableCell>
                    <TitleTableCell align="center">주소</TitleTableCell>
                    <TitleTableCell align="center">제품명</TitleTableCell>
                    <TitleTableCell align="center">S/N</TitleTableCell>
                    <TitleTableCell align="center">정보수정일</TitleTableCell>
                    <TitleTableCell align="center">선택</TitleTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {custList.length > 0 ? (
                    custList.map((item, index) => (
                      <TableRow key={item.serialNum + item.custName + index}>
                        <BodyTableCell>{item.custName ? item.custName : ""}</BodyTableCell>
                        <BodyTableCell>{item.telephone ? item.telephone : ""}</BodyTableCell>
                        <BodyTableCell>{item.address ? item.address : ""}</BodyTableCell>
                        <BodyTableCell>{item.categoryName ? item.categoryName : ""}</BodyTableCell>
                        <BodyTableCell>{item.serialNum ? item.serialNum : ""}</BodyTableCell>
                        <BodyTableCell>{item.completedDate ? item.completedDate : ""}</BodyTableCell>
                        <BodyTableCell>
                          <LinkButton onClick={() => selectInfo(item)}>선택</LinkButton>
                        </BodyTableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <BodyTableCell colSpan={7}>{"데이터가 없습니다."}</BodyTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default InfoDetailModal;
