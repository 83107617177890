import { Grid, Pagination, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  ColumnDirective,
  ColumnsDirective,
  ExcelExport,
  GridComponent,
  Inject,
  Resize,
  Toolbar,
  ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import { ClickEventArgs } from "@syncfusion/ej2-react-navigations";
import TitleDiv from "components/Common/TitleDiv";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { BodyTableCell, FormButton, FormDropDown, FormTextBox, TitleTableCell } from "style/theme";
import CRMApi from "system/api/CRMApi";
import UserApi from "system/api/UserApi";
import { ErrorHandler } from "system/ApiService";
import { SearchDeliveryType } from "system/CRMConstants";
import { Branch, Delivery, GetInitSearchResult, INIT_SEARCHDELIVERY, SearchDelivery, SearchResult } from "system/types";

function DeliveryMain() {
  let grid: any;
  const [search, setSearch] = useState<SearchDelivery>(INIT_SEARCHDELIVERY);
  const [searchForm, setSearchForm] = useState<SearchDelivery>(INIT_SEARCHDELIVERY);
  const [delivery, setDelivery] = useState<SearchResult<Delivery>>(GetInitSearchResult([]));
  const [branch, setBranch] = useState<Branch[]>([]);
  const toolbarOptions: ToolbarItems[] = ["ExcelExport"];

  useEffect(() => {
    UserApi.GetBranchList()
      .then((res) => {
        setBranch(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, []);

  const onSearch = () => {
    setSearch({ ...searchForm, page: 1 });
  };

  useEffect(() => {
    CRMApi.GetDelivery(search)
      .then((res) => {
        setDelivery(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, [search]);

  const onChangeSearch = (e: any) => {
    setSearchForm({ ...searchForm, [e.target.name]: e.target.value });
  };

  const onChangeDate = (e: any) => {
    const inputValue = e.target.value ? e.target.value : "";
    const newDate: string = inputValue ? moment(inputValue).format("YYYY-MM-DD") : "";
    setSearchForm({ ...searchForm, [e.target.name]: newDate });
  };

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setSearch({ ...search, page: page });
  };

  const toolbarClick = (args: ClickEventArgs) => {
    if (grid && args.item.id === "grid_excelexport") {
      // const selectedRecords = grid.getSelectedRecords();
      // const exportProperties = {
      //   dataSource: selectedRecords,
      // };
      grid.showSpinner();
      grid.excelExport();
    }
  };

  const excelExportComplete = (): void => {
    if (grid) {
      grid.hideSpinner();
    }
  };

  const progressTemplate = useCallback((props: any): any => {
    if (props.salesEndStatus === 0) {
      return <>{"납품대기"}</>;
    } else {
      return <>{"납품완료"}</>;
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="Delivery"></TitleDiv>
      </Grid>
      <Grid item xs={8}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TitleTableCell align="center">항목</TitleTableCell>
                <BodyTableCell align="center" style={{ display: "flex" }}>
                  <FormDropDown
                    name="classify"
                    value={search.classify}
                    onChange={onChangeSearch}
                    cssClass="e-outline"
                    width={"120px"}
                    fields={{ text: "key", value: "label" }}
                    dataSource={SearchDeliveryType}
                  ></FormDropDown>
                  <FormTextBox
                    name="text"
                    value={search.text}
                    onChange={onChangeSearch}
                    cssClass="e-outline"
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell colSpan={2}>
                  <Grid style={{ display: "flex", justifyContent: "space-around" }}>
                    <RadioButtonComponent
                      name="setup"
                      value="2"
                      checked={search.setup === "2"}
                      onChange={onChangeSearch}
                      label="전체"
                    ></RadioButtonComponent>
                    <RadioButtonComponent
                      name="setup"
                      value="1"
                      checked={search.setup === "1"}
                      onChange={onChangeSearch}
                      label="납품완료"
                    ></RadioButtonComponent>
                    <RadioButtonComponent
                      name="setup"
                      value="0"
                      checked={search.setup === "0"}
                      onChange={onChangeSearch}
                      label="납품예정"
                    ></RadioButtonComponent>
                  </Grid>
                </BodyTableCell>
              </TableRow>
              <TableRow>
                <TitleTableCell align="center">날짜</TitleTableCell>
                <BodyTableCell style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                  <DatePickerComponent
                    name="startDate"
                    onChange={onChangeDate}
                    value={new Date(search.startDate)}
                    cssClass="e-outline"
                    width={"120px"}
                  ></DatePickerComponent>
                  <span> ~ </span>
                  <DatePickerComponent
                    name="endDate"
                    onChange={onChangeDate}
                    value={new Date(search.endDate)}
                    cssClass="e-outline"
                    width={"120px"}
                  ></DatePickerComponent>
                  <RadioButtonComponent
                    name="sales"
                    value="0"
                    checked={search.sales === "0"}
                    onChange={onChangeSearch}
                    label="설치요청일"
                  ></RadioButtonComponent>
                  <RadioButtonComponent
                    name="sales"
                    value="1"
                    checked={search.sales === "1"}
                    onChange={onChangeSearch}
                    label="설치일"
                  ></RadioButtonComponent>
                </BodyTableCell>
                <TitleTableCell style={{ width: "100px" }} align="center">
                  판매지사
                </TitleTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    dataSource={branch}
                    name="branch"
                    value={search.branch}
                    fields={{ text: "branchName", value: "branchName" }}
                    onChange={onChangeSearch}
                    cssClass="e-outline"
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormButton onClick={onSearch}>조회</FormButton>
                </BodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={12}>
        <GridComponent
          id="grid"
          dataSource={delivery.list}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          excelExportComplete={excelExportComplete}
          toolbarClick={toolbarClick}
          allowResizing={true}
          ref={(g) => (grid = g)}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="salesId"
              headerText="문서번호"
              textAlign="Center"
              headerTextAlign="Center"
              width={150}
            />
            <ColumnDirective
              field="custName"
              headerText="상호명"
              textAlign="Center"
              headerTextAlign="Center"
              width={150}
            />
            <ColumnDirective
              field="address"
              headerText="주소"
              textAlign="Center"
              headerTextAlign="Center"
              width={300}
            />
            <ColumnDirective
              field="phone"
              headerText="전화번호"
              textAlign="Center"
              headerTextAlign="Center"
              width={150}
            />
            <ColumnDirective
              field="parentCategoryName"
              headerText="제품군"
              textAlign="Center"
              headerTextAlign="Center"
              width={100}
            />
            <ColumnDirective
              field="categoryName"
              headerText="제품명"
              textAlign="Center"
              headerTextAlign="Center"
              width={150}
            />
            <ColumnDirective field="serial" headerText="SN" textAlign="Center" headerTextAlign="Center" width={150} />
            <ColumnDirective
              field="setupTeam"
              headerText="판매지사"
              textAlign="Center"
              headerTextAlign="Center"
              width={150}
            />
            <ColumnDirective
              field="setupResponsible"
              headerText="영업사원"
              textAlign="Center"
              headerTextAlign="Center"
              width={100}
            />
            <ColumnDirective
              field="lineamount"
              headerText="총판매가"
              textAlign="Center"
              headerTextAlign="Center"
              width={100}
            />
            <ColumnDirective
              field="salesDate"
              headerText="설치요청일"
              textAlign="Center"
              headerTextAlign="Center"
              width={150}
            />
            <ColumnDirective
              field="salesDate"
              headerText="설치일"
              textAlign="Center"
              headerTextAlign="Center"
              width={150}
            />
            <ColumnDirective
              field="salesEndStatus"
              headerText="진행상황"
              textAlign="Center"
              headerTextAlign="Center"
              width={100}
              template={progressTemplate}
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, Resize]} />
        </GridComponent>
        <Pagination
          count={delivery.totalPage}
          page={search.page}
          onChange={onPageChange}
          showFirstButton
          showLastButton
        />
      </Grid>
    </Grid>
  );
}

export default DeliveryMain;
