import { ReactElement, useEffect } from "react";

function MainPage(): ReactElement {
  useEffect(() => {}, []);

  return (
    <>
      <div>메인페이지</div>
    </>
  );
}

export default MainPage;
