import { Grid } from "@mui/material";
import { ChartComponent } from "@syncfusion/ej2-react-charts";
import {
  AxisModel,
  Category,
  DataLabel,
  Inject,
  Legend,
  SeriesCollectionDirective,
  SeriesDirective,
  SplineSeries,
  StripLine,
  Tooltip,
} from "@syncfusion/ej2-react-charts/src";
import { useTranslation } from "react-i18next";
import { NoiseData } from "system/types";

interface NoiseGraphProps {
  underLine: number;
  data: NoiseData[];
}
function NoiseGraph({ underLine, data }: NoiseGraphProps) {
  const { t } = useTranslation();
  const tooltip = { enable: true };
  const primaryXAxis: AxisModel = {
    valueType: "Category",
    labelPlacement: "OnTicks",
    labelRotation: -45,
  };
  const primaryYAxis: AxisModel = {
    stripLines: [{ start: underLine, size: 2, sizeType: "Pixel", dashArray: "4", color: "red", zIndex: "Over" }],
    minimum: 0,
    maximum: 100,
    interval: 10,
    labelFormat: "{value}mV",
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {data.length === 0 ? (
          <>{t("no-data-30days")}</>
        ) : (
          <ChartComponent
            id="charts"
            primaryXAxis={primaryXAxis}
            primaryYAxis={primaryYAxis}
            tooltip={tooltip}
            height="600px"
          >
            <Inject services={[SplineSeries, Legend, StripLine, Tooltip, DataLabel, Category]} />
            <SeriesCollectionDirective>
              <SeriesDirective
                dataSource={data}
                xName="datetime"
                yName="maxNoise"
                fill="#9A0028"
                width={2}
                type="Spline"
              ></SeriesDirective>
            </SeriesCollectionDirective>
          </ChartComponent>
        )}
      </Grid>
    </Grid>
  );
}

export default NoiseGraph;
