import { Grid, Typography } from "@mui/material";
import { ButtonComponent, CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import UserApi from "system/api/UserApi";
import { ErrorHandler } from "system/ApiService";
import { useLoadingDispatch, useLoadingState } from "system/context/LoadingContext";
import useLocationSearch from "system/context/useLocationSearch";
import useLocationStateMatch from "system/context/useLocationStateMatch";
import { useUserDispatch } from "system/context/UserContext";
import { CommonRoutes, CRMRoutes, ProductSearchRoutes } from "system/types/routeList";

interface Props {
  onChange: (type: string) => void;
}

function LoginPage(props: Props): ReactElement {
  const dispatch = useUserDispatch();
  const loadingDispatch = useLoadingDispatch();
  const { loading } = useLoadingState();
  const history = useHistory();
  const requestedPath = useLocationStateMatch("requestedPath");
  const requestSearch = useLocationSearch();
  const [state, setState] = useState({ userId: "", userPw: "" });
  const [isRemember, setIsRemember] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["rememberId"]);

  const onChangeText = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsRemember(e.target.checked);
    if (e.target.checked) {
      setCookie("rememberId", state.userId, { maxAge: 2592000 });
    } else {
      removeCookie("rememberId");
    }
  };

  useEffect(() => {
    if (cookies.rememberId !== undefined) {
      setState({ ...state, userId: cookies.rememberId });
      setIsRemember(true);
    }
    if (requestedPath && requestedPath !== CommonRoutes.login) {
      loadingDispatch({ type: "LOADING" });
      UserApi.Refresh()
        .then((data) => {
          dispatch({ type: "LOGIN", item: data.user });
          UserApi.SetToken(data.token);
          history.push({
            pathname: requestedPath,
            state: undefined,
            search: requestSearch,
          });
        })
        .catch(() => {
          history.push(CommonRoutes.login);
        })
        .finally(() => {
          loadingDispatch({ type: "COMPLETE" });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickSubmit = () => {
    UserApi.Login(state.userId, state.userPw)
      .then((data) => {
        dispatch({ type: "LOGIN", item: data.user });
        UserApi.SetToken(data.token);
        if (requestedPath && requestedPath !== CommonRoutes.login) {
          history.push({
            pathname: requestedPath,
            state: undefined,
          });
        } else {
          if (data.user.authority === "Engineer" || data.user.authority === "User") {
            history.push(ProductSearchRoutes.root);
          } else {
            history.push(CRMRoutes.search);
          }
        }
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        if (error.response) {
          switch (error.response.status) {
            case 403: //forbidden
            case 404:
              msg = "The id or password you entered is incorrect.";
              break;
          }
        }
        alert(msg);
      });
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onClickSubmit();
  };

  if (loading) {
    return <></>;
  } else
    return (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">CS Web</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextBoxComponent
              cssClass="e-outline"
              placeholder="ID"
              name="userId"
              value={state.userId}
              onChange={onChangeText}
              style={{ height: "52px" }}
            ></TextBoxComponent>
          </Grid>
          <Grid item xs={12}>
            <TextBoxComponent
              cssClass="e-outline"
              placeholder="Password"
              type="password"
              name="userPw"
              value={state.userPw}
              onChange={onChangeText}
              style={{ height: "52px" }}
            ></TextBoxComponent>
          </Grid>
          <Grid item xs={12} display="flex">
            <CheckBoxComponent checked={isRemember} onChange={handleOnChange} name="rememberId" label="Remember Id" />
          </Grid>
          <Grid item xs={12}>
            <ButtonComponent
              style={{ width: "100%", height: "52px", backgroundColor: "#91001e", color: "white" }}
              type="submit"
            >
              Login
            </ButtonComponent>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className="help-link"
              variant="body2"
              role="button"
              style={{ cursor: "pointer" }}
              onClick={() => props.onChange("password")}
            >
              Forgot password?
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Typography variant="body2">New to CS Site?</Typography>
            <Typography
              className="help-link"
              variant="body2"
              role="button"
              style={{ cursor: "pointer", marginLeft: "5px" }}
              onClick={() => props.onChange("signup")}
            >
              Create an account.
            </Typography>
          </Grid>
        </Grid>
      </form>
    );
}

export default LoginPage;
