import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { BodyTableCell, FormButton, TitleTableCell } from "style/theme";
import CRMApi from "system/api/CRMApi";
import ERPApi from "system/api/ERPApi";
import { ErrorHandler } from "system/ApiService";
import { useLoadingDispatch } from "system/context/LoadingContext";
import { CSR, INIT_PRODINFO, ProdInfo, TransInfo } from "system/types";

interface RegisterModalProps {
  open: boolean;
  onClose: () => void;
  serial: string;
  data: CSR;
  setData: React.Dispatch<React.SetStateAction<any>>;
  setFlag: React.Dispatch<React.SetStateAction<any>>;
}

function RegisterModal({ open, onClose, serial, data, setData, setFlag }: RegisterModalProps) {
  const [prodInfo, setProdiInfo] = useState<ProdInfo>(INIT_PRODINFO);
  const [transInfo, setTransInfo] = useState<TransInfo[]>([]);
  const Loading = useLoadingDispatch();

  useEffect(() => {
    if (serial !== "" && open) {
      const fetchData = async () => {
        Loading({ type: "LOADING" });
        try {
          const [prodRes, transRes] = await Promise.all([CRMApi.GetProdInfo(serial), ERPApi.GetProdTransList(serial)]);
          setProdiInfo(prodRes);
          if (Array.isArray(transRes)) {
            setTransInfo(transRes);
          } else {
            setTransInfo([]);
          }
        } catch (err: any) {
          let msg = ErrorHandler(err);
          console.log(msg);
        } finally {
          Loading({ type: "COMPLETE" });
        }
      };
      fetchData();
    }
  }, [Loading, open, serial]);

  const onClickUpdate = () => {
    const updatedProdInfo = { ...prodInfo };

    // prodId 값이 0이 아니면 기존 prodId를 유지
    if (data.prodInfo.prodId !== 0) {
      updatedProdInfo.prodId = data.prodInfo.prodId;
    }

    setData({ ...data, prodInfo: updatedProdInfo });
    setFlag(true);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={"lg"}>
      <DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "50px" }}>
        <Grid container spacing={2} textAlign="center">
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TitleTableCell align="center">SN</TitleTableCell>
                    <TitleTableCell align="center">제품명</TitleTableCell>
                    <TitleTableCell align="center">제품군</TitleTableCell>
                    <TitleTableCell align="center">생산일</TitleTableCell>
                    <TitleTableCell align="center">생산자</TitleTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <BodyTableCell>{prodInfo.serialNum}</BodyTableCell>
                    <BodyTableCell>{prodInfo.parentCategoryName}</BodyTableCell>
                    <BodyTableCell>{prodInfo.categoryName}</BodyTableCell>
                    <BodyTableCell>{prodInfo.prodDate}</BodyTableCell>
                    <BodyTableCell>{prodInfo.productionPerson}</BodyTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">위 정보를 등록하시겠습니까?</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormButton onClick={onClickUpdate}>예</FormButton>
            <FormButton onClick={onClose}>아니오</FormButton>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TitleTableCell align="center">SN</TitleTableCell>
                    <TitleTableCell align="center">제품명</TitleTableCell>
                    <TitleTableCell align="center">날짜</TitleTableCell>
                    <TitleTableCell align="center">이동</TitleTableCell>
                    <TitleTableCell align="center">인계처</TitleTableCell>
                    <TitleTableCell align="center">장비위치</TitleTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transInfo.map((t: TransInfo, index: number) => (
                    <TableRow key={index}>
                      <BodyTableCell>{t.inventSerialId}</BodyTableCell>
                      <BodyTableCell>{prodInfo.parentCategoryName}</BodyTableCell>
                      <BodyTableCell>{t.datePhysical.split("T")[0]}</BodyTableCell>
                      <BodyTableCell>{t.referenceCategory}</BodyTableCell>
                      <BodyTableCell>{t.inventLocationName}</BodyTableCell>
                      <BodyTableCell>{t.deliveryName}</BodyTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default RegisterModal;
