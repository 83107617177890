import { styled, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

export const TitleTableCell = styled(TableCell)({
  textAlign: "center",
  whiteSpace: "nowrap",
  backgroundColor: "#EDEDED",
  border: "1px solid #E0E0E0",
  padding: "4px",
  fontSize: "12px",
});

export const BodyTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: "center",
  border: "1px solid #E0E0E0",
  padding: "4px",
  fontSize: "12px",
}));

interface SignBoxProps {
  type?: string;
}

function SignBox({ type }: SignBoxProps) {
  if (type === "customer") {
    return (
      <div style={{ width: "100px", display: "flex", alignItems: "flex-end" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TitleTableCell>고객 확인</TitleTableCell>
              </TableRow>
              <TableRow>
                <BodyTableCell height={"50px"}></BodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else {
    return (
      <div style={{ width: "100%", marginBottom: "5px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TitleTableCell width={"50px"} rowSpan={2}>
                  결재
                </TitleTableCell>
                <BodyTableCell>CS 팀장</BodyTableCell>
              </TableRow>
              <TableRow>
                <BodyTableCell height={"50px"}></BodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default SignBox;
