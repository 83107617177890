import { Dialog, DialogContent, Grid, Typography } from "@mui/material";
import PasswordInput from "components/Common/PasswordInput";
import TitleDiv from "components/Common/TitleDiv";
import { useEffect, useMemo, useState } from "react";
import { DarkButton, LightButton, RedButton, SearchBox, SearchDropDown } from "style/theme";
import UserApi from "system/api/UserApi";
import { ErrorHandler } from "system/ApiService";
import { AuthorityType } from "system/Constants";
import { useLoadingDispatch } from "system/context/LoadingContext";
import { isEmail, isValidPassword } from "system/Helper";
import { Branch, pageType, User } from "system/types";

interface AccountModalProps {
  open: boolean;
  onClose: () => void;
  data: User;
  type: pageType;
}

const validationUser = (type: pageType, data: User): boolean => {
  if (type === "Add") {
    if (!data.id || typeof data.id !== "string" || data.id.length < 2) {
      alert("ID를 2자 이상 입력해주세요.");
      return false;
    } else if (!isValidPassword(data.password)) {
      alert("비밀번호 형식을 올바르게 입력해주세요.");
      return false;
    }
  }

  if (!data.name || typeof data.name !== "string" || data.name.length < 2) {
    alert("이름을 2자 이상 입력해주세요.");
    return false;
  } else if (!isEmail(data.email)) {
    alert("이메일 형식이 맞지 않습니다.");
    return false;
  } else if (data.password && typeof data.password === "string" && data.password.length > 1) {
    if (!isValidPassword(data.password)) {
      alert("비밀번호는 최소 8자 이상이어야 하며, 문자, 숫자, 기호를 포함해야 합니다.");
      return false;
    }
  }

  return true;
};

function AccountModal({ open, onClose, data: _data, type }: AccountModalProps) {
  const loading = useLoadingDispatch();
  const [data, setData] = useState<User>(_data);
  const [branch, setBranch] = useState<Branch[]>([]);
  const AuthorityFields: object = { text: "label", value: "key" };
  const BranchFields: object = { text: "branchName", value: "branchId" };

  useMemo(() => {
    UserApi.GetBranchList()
      .then((res) => {
        setBranch(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, []);

  useEffect(() => {
    setData(_data);
  }, [_data]);

  const handleAdd = () => {
    if (validationUser(type, data)) {
      loading({ type: "LOADING" });
      UserApi.CreateUser(data)
        .then(() => {
          alert("계정이 생성되었습니다.");
          onClose();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        })
        .finally(() => loading({ type: "COMPLETE" }));
    }
  };

  const handleUpdate = () => {
    if (validationUser(type, data)) {
      loading({ type: "LOADING" });
      UserApi.UpdateUser(data)
        .then(() => {
          alert("계정이 수정되었습니다.");
          onClose();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        })
        .finally(() => loading({ type: "COMPLETE" }));
    }
  };

  const onChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onDelete = () => {
    if (window.confirm("삭제 후 계정을 복구할 수 없습니다. 그래도 삭제하시겠습니까?")) {
      UserApi.DeleteUser(data.id)
        .then((res) => {
          alert("삭제되었습니다.");
          onClose();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent style={{ padding: "56px", width: "600px" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TitleDiv title="계정 관리" />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" fontWeight="bold" width="150px">
              ID
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {type === "Add" ? (
              <SearchBox name="id" width="100%" cssClass="e-outline" onChange={onChange} value={data.id} />
            ) : (
              <Typography style={{ padding: "10px 12px 9px" }}>{data.id}</Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" fontWeight="bold" width="150px">
              Name
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <SearchBox name="name" width="100%" cssClass="e-outline" onChange={onChange} value={data.name}></SearchBox>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" fontWeight="bold" width="150px">
              Email
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <SearchBox
              name="email"
              width="100%"
              cssClass="e-outline"
              onChange={onChange}
              value={data.email}
            ></SearchBox>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" fontWeight="bold" width="150px">
              Authority
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <SearchDropDown
              name="authority"
              width="100%"
              cssClass="e-outline"
              dataSource={AuthorityType}
              fields={AuthorityFields}
              onChange={onChange}
              value={data.authority}
            ></SearchDropDown>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" fontWeight="bold" width="150px">
              Branch
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <SearchDropDown
              name="branch"
              width="100%"
              cssClass="e-outline"
              dataSource={branch}
              fields={BranchFields}
              onChange={onChange}
              value={data.branch}
            ></SearchDropDown>
          </Grid>
          {type !== "Add" && (
            <>
              {
                "*이미 사용중인 계정입니다. 비밀번호 변경을 원하시면 새로운 비밀번호를 입력 후 저장을 눌러주세요. 변경 후 사용자에게 비밀번호를 전달해야합니다."
              }
            </>
          )}
          <Grid item xs={3}>
            <Typography variant="body2" fontWeight="bold" width="150px">
              Password
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <PasswordInput value={data.password} name="password" fullWidth={true} onChange={onChange}></PasswordInput>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ marginTop: "70px", display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <RedButton onClick={onDelete}>삭제</RedButton>
          <Grid>
            <LightButton onClick={onClose}>취소</LightButton>
            <DarkButton
              onClick={type === "Add" ? handleAdd : handleUpdate}
              style={{ marginBottom: "0px", marginLeft: "8px" }}
            >
              저장
            </DarkButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default AccountModal;
