import client from "system/ApiService";
import HttpClient from "system/http-client";
import {
  ChangeCategory,
  CRMFile,
  CSR,
  CSRSearch,
  CSRSearchList,
  Message,
  SearchCalendar,
  SearchCRMAPI,
  SearchDelivery,
  SOList,
  VisitDetail,
  VisitMemo,
} from "system/types";

class _CRMApi {
  private client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }

  // search 상호 - 제품 list 조회
  GetSearchResult(data: CSRSearch) {
    return this.client.post(`crm/search`, data);
  }
  // 상호 - 제품 detail 조회
  GetCustProdInfo(item: CSRSearchList) {
    return this.client.post(`crm/custprod`, item);
  }
  GetProdInfo(serial: string) {
    return this.client.post("crm/prodinfo", { serial: serial });
  }
  GetWaitingPayment(serial: string) {
    return this.client.post(`crm/waitingPayment`, { serial: serial });
  }
  // csr 이력 정보
  GetCSRHistory(data: CSR) {
    return this.client.post(`crm/history`, data);
  }
  // 주소 - 지사 list 조회
  GetAddress() {
    return this.client.get(`crm/address`);
  }
  // 사업자 list 조회
  GetCompanyInfo() {
    return this.client.get(`crm/company`);
  }
  // 제품 카테고리 tree list 조회
  ChangeCategory(data: ChangeCategory) {
    return this.client.post(`crm/itemcategory`, data);
  }
  GetItemCategory(admin: boolean) {
    return this.client.get(`crm/itemcategory/${admin}`);
  }
  // 불량 구분, 처리 결과 tree list 조회
  GetNGCategory() {
    return this.client.get(`crm/ngcategory`);
  }
  GetResultCategory() {
    return this.client.get(`crm/resultcategory`);
  }
  // activity, project, cost center list 조회
  GetAPC() {
    return this.client.get(`crm/apc`);
  }
  // 제품 자재 전체 list 조회
  GetItemInfo() {
    return this.client.get(`crm/iteminfo`);
  }
  // 판매주문 출고유형, 창고, 모음, 납품모드 list 조회
  GetSOInfo() {
    return this.client.get(`crm/soinfo`);
  }
  // csr cru
  GetContactMemo(serial: string) {
    return this.client.post("crm/custmemo", { serial: serial });
  }
  GetCRMDetail(caseId: string) {
    return this.client.get(`crm/csr/${caseId}`);
  }
  InsertCSR(data: CSR) {
    return this.client.post(`crm/csr`, data);
  }
  UpsertCSR(data: CSR) {
    return this.client.put(`crm/csr`, data);
  }
  DeleteCSR(data: CSR) {
    return this.client.delete(`crm/csr`, data);
  }
  DeleteCSRSO(data: SOList) {
    return this.client.delete("crm/csr/so", data);
  }
  DeleteCSRContact(data: number) {
    return this.client.delete(`crm/csr/contact/${data}`);
  }
  // crm 조회
  GetCRMList(data: SearchCRMAPI) {
    return this.client.post("crm", data);
  }
  GetCalendar(data: SearchCalendar) {
    return this.client.post("crm/calendar", data);
  }
  GetVisitList(data: SearchCalendar) {
    return this.client.post("crm/visit", data);
  }
  UpsertVisitMemo(data: VisitMemo) {
    return this.client.post("crm/visitMemo", data);
  }
  UpsertVisitDetail(data: VisitDetail) {
    return this.client.post("crm/visitDetail", data);
  }
  // delivery
  GetDelivery(data: SearchDelivery) {
    return this.client.post("crm/delivery", data);
  }
  // 문자 및 메일 전송
  SendMessage(data: Message) {
    return this.client.post(`message/msg`, data);
  }
  SendEmail(data: Message) {
    return this.client.post(`message/email`, data);
  }
  GetMessageList(phone: string) {
    return this.client.post(`message`, { phone: phone });
  }
  // file
  GetFileList(caseId: string) {
    return this.client.get(`crm/file/${caseId}`);
  }
  DownloadFile(file: string, contentType: string) {
    return client.get(`file/download-file/${file}`, {
      responseType: "blob",
      headers: {
        "Content-Type": contentType,
      },
    });
  }
  UpdateFile(data: FormData) {
    return this.client.post(`crm/file`, data);
  }
  DeleteFile(data: CRMFile) {
    return this.client.delete("crm/file", data);
  }
  // search 엑셀 다운로드
  DownloadSearchExcel(data: SearchCRMAPI) {
    return client.post(`crm/search/excel`, data, {
      responseType: "blob",
    });
  }
  // po 엑셀로 다운로드
  DownloadEstimate(id: string) {
    return client.get(`crm/estimate/${id}`, {
      responseType: "blob",
    });
  }
  DownloadTrading(id: string) {
    return client.get(`crm/trading/${id}`, {
      responseType: "blob",
    });
  }
  DownloadInspect(id: string) {
    return client.get(`crm/inspect/${id}`, {
      responseType: "blob",
    });
  }
}

const CRMApi = new _CRMApi(client);
export default CRMApi;
