import { Grid, Tab, Tabs } from "@mui/material";
import TitleDiv from "components/Common/TitleDiv";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PRIMARY } from "style/theme";
import ChangePassword from "./ChangePassword";
import UserDetail from "./UserDetail";

function UserMainPage() {
  const { t } = useTranslation();
  const [value, setValue] = useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleDiv title="My Profile"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          TabIndicatorProps={{
            style: { background: PRIMARY },
          }}
        >
          <Tab value={1} label={t("my-profile")} />
          <Tab value={2} label={t("change-pwd")} />
        </Tabs>
        <br />
        <br />
        {value === 1 && <UserDetail></UserDetail>}
        {value === 2 && <ChangePassword></ChangePassword>}
      </Grid>
    </Grid>
  );
}

export default UserMainPage;
