import { Grid } from "@mui/material";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  PageSettingsModel,
  TextWrapSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import TitleDiv from "components/Common/TitleDiv";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DarkButton } from "style/theme";
import { EventList } from "system/types";

function EventReportList() {
  const { t } = useTranslation();
  const [data, setData] = useState<EventList[]>([]);
  const [search, setSearch] = useState<string>("");
  const settings: TextWrapSettingsModel = { wrapMode: "Content" };
  const pageOptions: PageSettingsModel = { pageSize: 20 };
  const storedData = localStorage.getItem("qc-data");

  useEffect(() => {
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (parsedData && parsedData.event) {
        setData(parsedData.event);
      }
    }
  }, [storedData]);

  const onChange = (args: any) => {
    setSearch(args.target.value);
  };

  const onSearch = () => {
    if (search === "") {
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        if (parsedData && parsedData.event) {
          setData(parsedData.event);
        }
      }
    } else {
      setData(data.filter((x) => x.eventCode === search));
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="space-between">
        <Grid>
          <TitleDiv title="Event Report"></TitleDiv>
        </Grid>
        <Grid display="flex" alignItems="center">
          <TextBoxComponent
            onChange={onChange}
            value={search}
            cssClass="e-outline"
            placeholder="Code"
          ></TextBoxComponent>
          <DarkButton onClick={onSearch} style={{ marginLeft: "16px" }}>
            {t("search")}
          </DarkButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <hr style={{ border: "1px solid #d9d9d9" }} />
      </Grid>
      <Grid item xs={12} style={{ marginTop: "40px", marginBottom: "80px" }}>
        <GridComponent
          className="eventReport"
          dataSource={data}
          allowTextWrap={true}
          textWrapSettings={settings}
          allowPaging={true}
          pageSettings={pageOptions}
          gridLines="Both"
        >
          <ColumnsDirective>
            <ColumnDirective
              field="datetime"
              headerText={t("dateandtime")}
              width="100"
              headerTextAlign="Center"
              textAlign="Center"
            />
            <ColumnDirective field="eventCode" headerText={t("event-code")} width="120" headerTextAlign="Center" />
            <ColumnDirective field="part" headerText={t("part")} width="120" headerTextAlign="Center" />
            <ColumnDirective
              field="situation"
              headerText={t("problem-description")}
              width="200"
              headerTextAlign="Center"
            />
            <ColumnDirective
              field="possibility"
              headerText={t("possible-cause")}
              width="200"
              headerTextAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Page]} />
        </GridComponent>
      </Grid>
    </Grid>
  );
}

export default EventReportList;
