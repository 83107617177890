export const NationType = [
  { key: "kr", label: "Korea" },
  { key: "en", label: "USA" },
];
export const AuthorityType = [
  { key: "Admin", label: "Admin" },
  { key: "HeadA", label: "본사 사용자 A" },
  { key: "HeadB", label: "본사 사용자 B" },
  { key: "BranchUser", label: "지사 사용자" },
  { key: "Engineer", label: "Engineer" },
  { key: "User", label: "User" },
];
export const EquipCountryCodeISOType = [
  "None",
  "Argentina",
  "Australia",
  "Brazil",
  "Bulgaria",
  "Canada",
  "Chile",
  "China",
  "Taiwan",
  "Colombia",
  "CostaRica",
  "Cuba",
  "Czech",
  "Denmark",
  "Ecuador",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "HongKong",
  "Hungary",
  "India",
  "Indonesia",
  "Iran",
  "Israel",
  "Italy",
  "Japan",
  "RepublicOfKorea",
  "Lebanon",
  "Malaysia",
  "Mexico",
  "Netherlands",
  "Norway",
  "Peru",
  "Poland",
  "Portugal",
  "PuertoRico",
  "Romania",
  "Russia_Kazakhstan",
  "SaudiArabia",
  "Serbia",
  "Singapore",
  "Vietnam",
  "RepublicOfSouthAfrica",
  "Spain",
  "Sweden",
  "Switzerland",
  "Thailand",
  "UAE",
  "Turkey",
  "Ukraine",
  "Egypt",
  "UnitedKingdom",
  "USA",
  "Venezuela",
];
export const EquipLanguageType = [
  "None",
  "English",
  "Arab",
  "Bulgaria",
  "Chinese",
  "Czech",
  "Finnish",
  "French",
  "German",
  "Greek",
  "Italy",
  "Japanese",
  "Korea",
  "Netherlands",
  "Polish",
  "Portuguese",
  "PortugueseBrazil",
  "Romania",
  "Russian",
  "Slovak",
  "Spanish",
  "SpanishMexico",
  "Taiwan",
  "Thiland",
  "Turkish",
];

export const EquipConnectSerialPortType = [
  { key: "NotUse", label: "Off" },
  { key: "LB120", label: "LB120" },
  { key: "BloodPressure", label: "Blood Pressure" },
  { key: "Height", label: "Height" },
  { key: "ThermalPrinter", label: "Thermal Printer" },
  { key: "SD400", label: "SD400" },
  { key: "NAYAX", label: "NAYAX" },
];

export const EquipUnitTypestring = [
  { key: "KG_CM", label: "kg/cm" },
  { key: "KG_FtIn", label: "kg/ft in" },
  { key: "Pound_CM", label: "lb/cm" },
  { key: "Pound_FtIn", label: "lb/ft in" },
];
