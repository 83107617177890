import { styled, Typography } from "@mui/material";
import React from "react";

const HeaderTitleBar = styled("div")(({ theme }) => ({
  letterSpacing: "-0.5px",
  marginBottom: "1rem",
}));

interface TitleDivProps {
  title: string;
  children?: React.ReactNode;
}

function TitleDiv({ title, children }: TitleDivProps) {
  return (
    <HeaderTitleBar>
      <Typography variant="h5" fontWeight="bold">
        {title}
      </Typography>
      <Typography variant="body1" color="#bbb">
        {children}
      </Typography>
    </HeaderTitleBar>
  );
}

export default TitleDiv;
