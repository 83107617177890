import { Collapse, CssBaseline, Drawer, Grid, List, ListItem, ListItemText } from "@mui/material";
import { styled } from "@mui/material//styles";
import Calendar from "components/CRM/Calendar/Calendar";
import React from "react";
import { Link } from "react-router-dom";
import { theme } from "style/theme";
import { useUserState } from "system/context/UserContext";
import { INIT_CRMSEARCH } from "system/types";
import { CRMRoutes, ProductSearchRoutes } from "system/types/routeList";
import useSearchStore from "system/useSearchStore";

const drawerWidth = 240;

const DrawerMain = styled("div")(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    fontSize: "20px",
    weight: "bold",
    textAlign: "center",
    backgroundColor: "#3c3c3b",
    "& a:hover": {
      color: "#ffffff",
      backgroundColor: "#545453",
      filter: "brightness(1.2)",
    },
  },
  "& .MuiListItemText-primary	": {
    color: "#fff",
  },
}));

const NavList = styled(List)<{ component?: React.ElementType }>({
  "& 	.MuiListItemText-root": {
    paddingLeft: theme.spacing(1.1),
  },

  "& .MuiList-root": {
    backgroundColor: "#545453",
  },
  "& .MuiList-root .MuiListItem-root": {
    paddingLeft: theme.spacing(3),
  },
});

interface PersistentDrawerLeftProps {
  open: boolean;
}

export default function PersistentDrawerLeft({ open }: PersistentDrawerLeftProps) {
  const user = useUserState();
  const { resetSearchForm } = useSearchStore();

  return (
    <>
      <CssBaseline />
      <DrawerMain>
        <Drawer variant="persistent" anchor="left" open={open}>
          {user.authority !== "" && (
            <Grid container direction="row" alignContent="space-between" style={{ height: "100%", marginTop: "65px" }}>
              <Grid item xs={12}>
                <NavList>
                  <ListItem>
                    <ListItemText primary="Product Search" />
                  </ListItem>
                  <Collapse in={true} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem component={Link} to={ProductSearchRoutes.root}>
                        <ListItemText
                          secondary="Product Search"
                          secondaryTypographyProps={{
                            fontWeight: "100",
                            color: "#dddddd",
                            fontSize: "1rem",
                          }}
                        />
                      </ListItem>
                      {user.branch === "HC005176" && (
                        <ListItem component={Link} to={ProductSearchRoutes.dashboard}>
                          <ListItemText
                            secondary="Dashboard"
                            secondaryTypographyProps={{
                              fontWeight: "100",
                              color: "#dddddd",
                              fontSize: "1rem",
                            }}
                          />
                        </ListItem>
                      )}
                    </List>
                  </Collapse>
                  {!["Engineer", "User"].includes(user.authority) && (
                    <>
                      <ListItem>
                        <ListItemText primary="CRM" />
                      </ListItem>
                      <Collapse in={true} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItem
                            component={Link}
                            to={CRMRoutes.search}
                            onClick={() => resetSearchForm(INIT_CRMSEARCH)}
                          >
                            <ListItemText
                              secondary="Search"
                              secondaryTypographyProps={{
                                fontWeight: "100",
                                color: "#dddddd",
                                fontSize: "1rem",
                              }}
                            />
                          </ListItem>
                          <ListItem component={Link} to={CRMRoutes.report}>
                            <ListItemText
                              secondary="Customer Service Report"
                              secondaryTypographyProps={{
                                fontWeight: "100",
                                color: "#dddddd",
                                fontSize: "1rem",
                              }}
                            />
                          </ListItem>
                          <ListItem component={Link} to={CRMRoutes.delivery}>
                            <ListItemText
                              secondary="Delivery"
                              secondaryTypographyProps={{
                                fontWeight: "100",
                                color: "#dddddd",
                                fontSize: "1rem",
                              }}
                            />
                          </ListItem>
                          {user.authority === "Admin" && (
                            <ListItem component={Link} to={CRMRoutes.admin}>
                              <ListItemText
                                secondary="Administrator Menu"
                                secondaryTypographyProps={{
                                  fontWeight: "100",
                                  color: "#dddddd",
                                  fontSize: "1rem",
                                }}
                              />
                            </ListItem>
                          )}
                        </List>
                      </Collapse>
                    </>
                  )}
                </NavList>
              </Grid>
              <Grid item xs={12} style={{ overflowX: "hidden" }}>
                {!["", "Engineer", "User"].includes(user.authority) && <Calendar />}
              </Grid>
            </Grid>
          )}
        </Drawer>
      </DrawerMain>
    </>
  );
}
