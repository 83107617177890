import { styled, Typography } from "@mui/material";

const HeaderTitleBar = styled("div")(({ theme }) => ({
  marginBottom: "4px",
}));

interface MainTitleDivProps {
  title: string;
}

function MainTitleDiv({ title }: MainTitleDivProps) {
  return (
    <HeaderTitleBar>
      <Typography variant="h6" fontWeight="bold">
        {title}
      </Typography>
    </HeaderTitleBar>
  );
}

export default MainTitleDiv;
