import moment from 'moment';
import { CSR } from './types';

export function isSplitInt(value: string) {
  const matchResult = value && value.match(/-?\d+(\.\d+)?/);
  if (matchResult) {
    const number = parseFloat(matchResult[0]);
    return number
  } else {
    return 0
  }
}
/**
 * 이메일 체크 정규식
 * @param value
 * @returns boolean
 */
export function isEmail(value: string) {
  const regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  return regExp.test(value); // 형식에 맞는 경우 true 리턴
}

/**
 * 비밀번호 체크 정규식 (8자~16자, 영문자,특수문자,숫자 각 1개 이상)
 * @param targetPassword
 * @returns boolean
 */
export function isValidPassword(targetPassword: string) {
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%^*#?&])[A-Za-z\d@$!%^*#?&]*$/g;
  if (targetPassword.length >= 8 && targetPassword.length <= 16) {
    return passwordRegex.test(targetPassword);
  }
  return false;
}

// 주말을 제외한 다음날 설정 (방문예정일)
export function isNextDay() {
    var today = moment();

    // 만약 오늘이 주말이라면
    if (today.day() === 5 || today.day() === 6) { // 토요일(6) 또는 일요일(0)
        // 다음 주 월요일의 날짜를 계산
        var nextMonday = today.clone().add(8 - today.day(), 'days').format("YYYY-MM-DD");
        return nextMonday;
    } else {
        // 내일 날짜를 계산
        var tomorrow = today.clone().add(1, 'days').format("YYYY-MM-DD");
        return tomorrow;
    }
}


// date format 변경 1999-1-10 13:23:42
export function isChangeDateFormat(value: string) {
  const originalDate = new Date(value);

  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
  const day = originalDate.getDate().toString().padStart(2, '0');
  const hours = originalDate.getHours().toString().padStart(2, '0');
  const minutes = originalDate.getMinutes().toString().padStart(2, '0');
  const seconds = originalDate.getSeconds().toString().padStart(2, '0');

  const formattedDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

  return formattedDateString;
}

export function CSRValidation(data: CSR) {
  if (data.custInfo.custName === "" || data.custInfo.branch === "") {
    alert("상호명과 담당지사 값을 필수입니다.");
    return false;
  }
  if (data.prodInfo.categoryName === "" || data.prodInfo.parentCategoryName === "") {
    alert("제품명은 필수입니다.");
    return false;
  }
  if (data.csInfo.callResult === "Call" && data.csInfo.completedPerson === "") {
      alert("전화처리의 경우 처리자 값은 필수입니다.");
      return false;
  }
  if (data.csInfo.handlingMethod === "Visit" && !["", "Invalid date"].includes(data.csInfo.completedDate)) {
    if (data.csInfo.resultForVisit === "Blank" || data.csInfo.paymentType === "Blank") {
      alert("처리일이 입력되어있는 경우 방문처리결과, 비용처리는 필수값입니다.");
      return false;
    }
  }
  if (data.csInfo.receivedDate !== "") {
    if (data.csInfo.ngCategoryId === "" || data.csInfo.ngCategoryDetailId === "") {
      alert("접수일 입력 시 불량구분대분류, 불량구분소분류는 필수값입니다.");
      return false;
    }
  }
  if (data.csInfo.completedDate !== "") {
    if (data.csInfo.resultCategoryId === "" || data.csInfo.resultCategoryDetailId === "") {
      alert("처리일 입력 시 처리결과대분류, 처리결과소분류는 필수값입니다.");
      return false;
    }
  }
  if (data.csInfo.paymentType === "Free" && data.csInfo.focReason === "Blank") {
    alert("무상처리근거를 선택해주세요.");
    return false;
  }
  return true;
}

export function SOValidation(data: CSR) {
  if (data.caseId === "") {
    alert("SO 생성을 위한 문서번호가 존재하지 않습니다.");
    return false;
  }
  if (data.serviceInfo.personnelNumber === "") {
    alert("SO처리자 사번을 입력해주세요.");
    return false;
  }
  if (data.serviceInfo.costCenter === "") {
    alert("Cost Center 값은 필수입니다.");
    return false;
  }
  if (data.csInfo.completedDate === "" || data.csInfo.completedDate === "Invaild Date") {
    alert("처리일은 필수값입니다.");
    return false;
  }
  if (data.serviceInfo.companyAccount === "") {
    alert("고객 검색을 통해 값을 입력해야합니다.")
    return false
  }
  if (
    data.soList.some((x) => x.issueTypeId === "C01") &&
    (data.serviceInfo.activity === "" || data.serviceInfo.project === "")
  ) {
    alert("Activity와 Project는 필수값입니다.");
    return false;
  }
  return true;
}