import { Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DarkButton } from "style/theme";

interface ChangePopUpProps {
  popup: boolean;
}

function ChangePopUp({ popup }: ChangePopUpProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(popup);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} maxWidth="md" style={{ marginTop: "65px" }}>
        <DialogContent style={{ padding: "40px 105px" }}>
          <Grid container spacing={2} display="flex" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" fontWeight="bold" paragraph textAlign="center">
                {t("restart")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign="center" style={{ marginTop: "15px" }}>
            <DarkButton onClick={handleClose}>{t("ok")}</DarkButton>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ChangePopUp;
