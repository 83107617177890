import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import TitleDiv from "components/Common/TitleDiv";
import { useState } from "react";
import { BodyTableCell, DarkButton, FormTextBox, LightButton, TitleTableCell } from "style/theme";
import { CSR } from "system/types";

interface ChangeNameModalProps {
  open: boolean;
  onClose: () => void;
  data: CSR;
  setData: React.Dispatch<React.SetStateAction<any>>;
}

function ChangeNameModal({ open, onClose, data, setData }: ChangeNameModalProps) {
  const [name, setName] = useState<string>("");

  const onClickUpdate = () => {
    const newData = { ...data };

    newData.custInfo = {
      ...newData.custInfo,
      custName: name,
    };
    setData(newData);
    onClose();
  };

  const onChangeInfo = (e: any) => {
    setName(e.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "50px" }}>
        <Grid container spacing={2} textAlign="center">
          <Grid item xs={12}>
            <TitleDiv title="고객이름 변경"></TitleDiv>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              고객이름을 변경합니다. 이 작업은 신중하게 진행해 주십시오. 이름을 변경하게 되면, AS이력과 연관된 CSR의
              고객이름도 모두 변경합니다. 해당 이름을 보존하고 싶으시면 이 창을 닫고{" "}
              <span style={{ fontSize: "15px", backgroundColor: "lightyellow" }}>고객신규</span>를 체크하시고, 이전
              사용처에 이름을 기입해주세요. 변경 후 <span style={{ fontSize: "15px", fontWeight: "bold" }}>저장</span>
              해주세요.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TitleTableCell align="center">기존 고객이름</TitleTableCell>
                    <BodyTableCell align="center">
                      <Typography variant="body2">{data.custInfo.custName}</Typography>
                    </BodyTableCell>
                  </TableRow>
                  <TableRow>
                    <TitleTableCell align="center">변경할 고객이름</TitleTableCell>
                    <BodyTableCell align="center">
                      <FormTextBox
                        name="custName"
                        cssClass="e-outline"
                        value={name}
                        onChange={onChangeInfo}
                      ></FormTextBox>
                    </BodyTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "40px" }}>
            <LightButton onClick={onClose}>취소</LightButton>
            <DarkButton onClick={onClickUpdate} style={{ marginBottom: "0px", marginLeft: "8px" }}>
              저장
            </DarkButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ChangeNameModal;
