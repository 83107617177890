import { Grid, Typography } from "@mui/material";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import PasswordInput from "components/Common/PasswordInput";
import { ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";
import UserApi from "system/api/UserApi";
import { useLoadingDispatch } from "system/context/LoadingContext";
import { isEmail, isValidPassword } from "system/Helper";
import { INIT_USER, User } from "system/types";
import { CommonRoutes } from "system/types/routeList";

interface NewUser extends User {
  confirmPassword: string;
}

interface SignUpProps {
  onChange: (type: string) => void;
}

const validationUser = (data: NewUser) => {
  if (data.id.length < 2) {
    alert("ID를 2자 이상 입력해주세요.");
    return false;
  } else if (data.name.length < 2) {
    alert("이름을 2자 이상 입력해주세요.");
    return false;
  } else if (!isEmail(data.email)) {
    alert("이메일 형식이 맞지 않습니다.");
    return false;
  } else if (!isValidPassword(data.password) || !isValidPassword(data.confirmPassword)) {
    alert("비밀번호 형식을 올바르게 입력해주세요.");
    return false;
  } else return true;
};

function SignUp(props: SignUpProps): ReactElement {
  const history = useHistory();
  const loading = useLoadingDispatch();

  const [data, setData] = useState<NewUser>({
    ...INIT_USER,
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState<NewUser>({
    ...INIT_USER,
    password: "",
    confirmPassword: "",
  });

  const onBlurInput = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    if (name === "confirmPassword") {
      if (value === data.password) {
        setError((prev) => ({ ...prev, confirmPassword: "" }));
      } else {
        setError((prev) => ({
          ...prev,
          confirmPassword: "Password are not matching",
        }));
      }
    } else if (name === "password") {
      if (isValidPassword(value)) {
        setError((prev) => ({ ...prev, password: "" }));
      } else {
        setError((prev) => ({ ...prev, password: "Invalid Password(8자~16자, 영문자,특수문자,숫자 각 1개 이상)" }));
      }
    }
  };

  const onChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSingup = () => {
    if (validationUser(data)) {
      loading({ type: "LOADING" });
      UserApi.CreateUser(data)
        .then(() => {
          alert("회원가입이 완료되었습니다. 관리자가 권한을 부여하기 전까지 사이트 기능이 제한될 수 있습니다.");
          history.push(CommonRoutes.login);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => loading({ type: "COMPLETE" }));
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h5">Sign Up</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextBoxComponent
          id="id"
          placeholder="ID"
          name="id"
          type="text"
          cssClass="e-outline"
          onChange={onChange}
          style={{ height: "52px" }}
        ></TextBoxComponent>
      </Grid>
      <Grid item xs={12}>
        <TextBoxComponent
          id="name"
          placeholder="Name"
          name="name"
          type="text"
          cssClass="e-outline"
          onChange={onChange}
          style={{ height: "52px" }}
        ></TextBoxComponent>
      </Grid>
      <Grid item xs={12}>
        <TextBoxComponent
          id="email"
          placeholder="Email"
          name="email"
          type="text"
          cssClass="e-outline"
          onChange={onChange}
          style={{ height: "52px" }}
        ></TextBoxComponent>
      </Grid>
      <Grid item xs={12}>
        <PasswordInput
          name="password"
          placeholder="Password"
          variant="outlined"
          fullWidth={true}
          error={error.password !== ""}
          helperText={error.password}
          onBlur={onBlurInput}
          onChange={onChange}
        ></PasswordInput>
      </Grid>
      <Grid item xs={12}>
        <PasswordInput
          name="confirmPassword"
          placeholder="Confirm Password"
          variant="outlined"
          fullWidth={true}
          error={error.confirmPassword !== ""}
          helperText={error.confirmPassword}
          onBlur={onBlurInput}
          onChange={onChange}
        ></PasswordInput>
      </Grid>
      <Grid item xs={12}>
        <ButtonComponent
          style={{ width: "100%", height: "52px", backgroundColor: "#91001e", color: "white" }}
          type="submit"
          onClick={onSingup}
        >
          Create Account
        </ButtonComponent>
      </Grid>
      <Grid item xs={12}>
        <Typography
          className="help-link"
          variant="body2"
          role="button"
          style={{ cursor: "pointer" }}
          onClick={() => props.onChange("signin")}
        >
          Back to sign-in
        </Typography>
      </Grid>
    </Grid>
  );
}

export default SignUp;
