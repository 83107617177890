import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from "./locales/en/translation.json";
import translationKO from "./locales/ko/translation.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    ko: {
      translation: translationKO,
    },
  },
  lng: 'ko', // 기본 언어
  fallbackLng: 'en', // 번역이 없을 경우 폴백 언어

  interpolation: {
    escapeValue: false, // React에서는 이미 XSS를 방지하므로 false로 설정
  },
});

export default i18n;