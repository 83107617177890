import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { TitleCell } from "style/theme";
import UserApi from "system/api/UserApi";
import { ErrorHandler } from "system/ApiService";
import { useUserState } from "system/context/UserContext";
import { Branch, INIT_USER, User } from "system/types";

function UserDetail() {
  const user = useUserState();
  const [data, setData] = useState<User>(INIT_USER);
  const [branch, setBranch] = useState<Branch[]>([]);

  useMemo(() => {
    UserApi.GetBranchList()
      .then((res) => {
        setBranch(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, []);

  useEffect(() => {
    if (user.id) {
      UserApi.GetUser(user.id)
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    }
  }, [user.id]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow style={{ borderTop: "1px solid #d3d3d3" }}>
                <TitleCell>ID</TitleCell>
                <TableCell>{data.id}</TableCell>
              </TableRow>
              <TableRow>
                <TitleCell>Name</TitleCell>
                <TableCell>{data.name}</TableCell>
              </TableRow>
              <TableRow>
                <TitleCell>Email</TitleCell>
                <TableCell>{data.email}</TableCell>
              </TableRow>
              <TableRow>
                <TitleCell>Branch</TitleCell>
                <TableCell>{branch.find((x) => x.branchId === data.branch)?.branchName}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default UserDetail;
