import { Box, Grid, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import MainTitleDiv from "components/Common/MainTitleDiv";
import TitleDiv from "components/Common/TitleDiv";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentCell, TitleCell, TitleHeaderCell } from "style/theme";
import ProductSearchApi from "system/api/ProductSearchApi";
import { ErrorHandler } from "system/ApiService";
import { useLoadingDispatch } from "system/context/LoadingContext";
import { MaterialHistory, ProductInfo } from "system/types";

interface DetailInfoProps {
  serial: string;
}

function DetailInfo({ serial }: DetailInfoProps) {
  const { t } = useTranslation();
  const [info, setInfo] = useState<ProductInfo>();
  const [materialHistory, setMaterialHistory] = useState<MaterialHistory[]>([]);
  const Loading = useLoadingDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        Loading({ type: "LOADING" });
        const infoRes = await ProductSearchApi.GetInfomationOfProduct(serial);
        setInfo(infoRes[0]);

        const materialHistoryRes = await ProductSearchApi.GetMaterialHistoryOfProduct(serial);
        setMaterialHistory(materialHistoryRes);
      } catch (err: any) {
        let msg = ErrorHandler(err);
        console.log(msg);
      } finally {
        Loading({ type: "COMPLETE" });
      }
    };

    fetchData();
  }, [Loading, serial]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} display="flex" justifyContent="space-between">
        <TitleDiv title="Device History Record"></TitleDiv>
      </Grid>
      <Grid item xs={9}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TitleCell>{t("business-name")}</TitleCell>
                <ContentCell>{info ? info.bizName : "-"}</ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>{t("current-location")}</TitleCell>
                <ContentCell>{info ? info.currentCustomer : "-"}</ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>{t("model")}</TitleCell>
                <ContentCell>{info ? info.itemName : "-"}</ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>{t("serial-number")}</TitleCell>
                <ContentCell>{serial}</ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>{t("manufacturer")}</TitleCell>
                <ContentCell>{info ? info.productionPerson : "-"}</ContentCell>
              </TableRow>
              <TableRow>
                <TitleCell>{t("manufacture-date")}</TitleCell>
                <ContentCell>{info ? info.prodDate : "-"}</ContentCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={3} style={{ paddingLeft: "80px" }}>
        <Grid item xs={12}>
          <Box
            sx={{
              width: 250,
              height: 360,
              border: "1px solid #d9d9d9",
              marginBottom: "28px",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <img
              alt="product-img"
              src={`/images/product/${info?.itemName.split(/_|\s/)[1] + ".jpg"}`}
              style={{
                width: "100%",
                height: "100%",
              }}
              onError={(e) => {
                e.currentTarget.src = "/images/NO_Image.png";
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <MainTitleDiv title={info?.itemName.split(/_|\s/)[1] || "-"}></MainTitleDiv>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "60px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow style={{ borderTop: "1px solid #d9d9d9" }}>
                <TitleHeaderCell>{t("major-management-part")}</TitleHeaderCell>
                <TitleHeaderCell>{t("serial-number")}</TitleHeaderCell>
                <TitleHeaderCell>{t("program-version1")}</TitleHeaderCell>
                <TitleHeaderCell>{t("program-version2")}</TitleHeaderCell>
              </TableRow>
              {materialHistory.map((e: MaterialHistory) => {
                return (
                  <TableRow key={e.materialItemId + e.materialSerialId}>
                    <ContentCell style={{ borderLeft: "1px solid #d9d9d9" }}>{e.materialItemName}</ContentCell>
                    <ContentCell>{e.materialSerialId}</ContentCell>
                    <ContentCell>{e.programVersion1}</ContentCell>
                    <ContentCell>{e.programVersion2}</ContentCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default DetailInfo;
