export enum CommonRoutes {
  root = "/",
  login = "/login",
}

export enum UserRoutes {
  user = "/user",
  profile = "/user/profile",
  account = "/user/account",
}

export enum ProductSearchRoutes {
  root = "/product-search",
  detail = "/product-search/detail/:nation/:id",
  eventReport = "/product-search/detail/event-report/:nation/:id",
  dashboard = "/product-search/dashboard"
}

export enum CRMRoutes {
  root = "/crm",
  visit = "/crm/visitList",
  multi = "/crm/multi",
  search = "/crm/search",
  report = "/crm/report",
  update = "/crm/report/:id?",
  view = "/crm/detail",
  detail = "/crm/detail/:id",
  dashboard = "/crm/dashboard",
  delivery = "/crm/delivery",
  admin = "/crm/admin",
}