import client from "system/ApiService";
import HttpClient from "system/http-client";
import { User } from "system/types";

class _UserApi {
  private client: HttpClient;
  private token: string;
  public constructor(client: HttpClient) {
    this.client = client;
    this.token = "";
  }
  // 로그인
  SetToken(token: string) {
    this.token = token;
    client.setToken(token);
  }
  GetToken() {
    return this.token;
  }
  Login(userId: string, userPassword: string) {
    return client.post<{
      user: User;
      token: string;
    }>("user/login", { id: userId, password: userPassword });
  }
  Refresh() {
    return client.post<{
      user: User;
      token: string;
    }>("user/refresh");
  }
  Logout() {
    return client.post("user/logout").then(() => {
      this.token = "";
    });
  }
  ChangePassword(userId: string, currentPassword: string, newPassword: string, confirmPassword: string) {
    return this.client.put(`user/change-password/${userId}`, {
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    });
  }
  ResetPassword(data: FormData) {
    return client.post(`user/reset-password`, data);
  }
  // 유저 조회 및 수정
  GetUser(userId: string) {
    return this.client.get(`user/${userId}`);
  }
  GetUserList() {
    return this.client.get("user");
  }
  CreateUser(data: User) {
    return this.client.post("user", data);
  }
  UpdateUser(data: User) {
    return this.client.put("user", data);
  }
  DeleteUser(id: string) {
    return this.client.delete(`user/${id}`);
  }
  // 권한 조회
  GetBranchList() {
    return this.client.get("user/branch");
  }
}

const UserApi = new _UserApi(client);
export default UserApi;
