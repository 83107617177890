import { Grid } from "@mui/material";
import { ColumnDirective, ColumnsDirective, KanbanComponent } from "@syncfusion/ej2-react-kanban";
import TitleDiv from "components/Common/TitleDiv";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "style/kanban.css";
import ProductSearchApi from "system/api/ProductSearchApi";
import { ErrorHandler } from "system/ApiService";
import { QCDashboard } from "system/types";
import { ProductSearchRoutes } from "system/types/routeList";

function DashboardMain() {
  const [data, setData] = useState<QCDashboard[]>([]);
  const history = useHistory();

  const onLoad = useCallback(() => {
    ProductSearchApi.GetDashboardList()
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const [start, setStart] = useState();
  const dragStart = (args: any) => {
    setStart(args.data[0].finalStatus); // 드래그 시작 시 초기 상태 저장
  };

  const dragStop = (args: any) => {
    const end = args.data[0].finalStatus; // 드래그 종료 시 최종 상태 저장
    if (start !== end) {
      // 시작 상태와 종료 상태가 다른 경우에만 API 호출
      const newqc: QCDashboard = {
        serial: args.data[0].serial,
        status: end,
      };
      if (newqc.serial !== "" && ["0", "1", "2"].includes(newqc.status)) {
        ProductSearchApi.PutDashboardStatus(newqc)
          .then(() => {
            alert("Success");
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
            onLoad();
          });
      }
    }
  };

  const dialogTemplate = (args: any) => {
    if (args.serial) {
      return history.push(`${ProductSearchRoutes.root}/detail/en/${args.serial}?tab=1`);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} display="flex" justifyContent="space-between">
        <TitleDiv title="Product Dashboard"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <KanbanComponent
          id="kanban"
          keyField="finalStatus"
          dataSource={data}
          cardSettings={{ contentField: "error", headerField: "serial" }}
          dragStop={dragStop}
          dragStart={dragStart}
          dialogSettings={{ template: dialogTemplate }}
        >
          <ColumnsDirective>
            <ColumnDirective headerText="Urgent" keyField="0" />
            <ColumnDirective headerText="In Progress" keyField="1" />
            <ColumnDirective headerText="Completed" keyField="2" allowDrag={false} />
          </ColumnsDirective>
        </KanbanComponent>
      </Grid>
    </Grid>
  );
}

export default DashboardMain;
