import { Grid } from "@mui/material";
import MainTitleDiv from "components/Common/MainTitleDiv";
import AdminCategory from "./AdminCategory";
import AdminItem from "./AdminItem";

function AdminMain() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AdminItem></AdminItem>
      </Grid>
      <Grid item xs={12}>
        <AdminCategory></AdminCategory>
      </Grid>
      <Grid item xs={12}>
        <MainTitleDiv title="해피콜 문자 발송"></MainTitleDiv>
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
}

export default AdminMain;
